import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientDataService } from '../services/data-service/client-data.service';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit {
  viewTrack;
  contract = {
    show: false,
    id: ''
  };
  contractSent: boolean = false;

  constructor(public dataService: ClientDataService, private router: Router, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    this.dataService.getUserData().then(() => {
      this.spinner.hide();
      if (!this.dataService?.currentUserData?.contract?.signed) {
        this.contract = { ...this.dataService?.currentUserData?.contract, show: true };
      }
    });
    this.dataService.getUserSpecificTracks();
  }

  trackDetails(track) {
    this.viewTrack = track;
    this.openModal('singleTrack');
  }

  openModal(modal) {
    document.getElementById(modal).classList.add('active');
  }

  closeModal(modal) {
    document.getElementById(modal).classList.remove('active');
  }

  routeTo(route) {
    if (this.router.url.includes('label')) {
      this.router.navigate(['/label/', route]);
    } else {
      this.router.navigate(['/artist/', route]);
    }
  }

  async sendContract() {
    this.spinner.show();
    await this.dataService.sendUserContract();
    this.contractSent = true;
    this.spinner.hide();
  }
}
