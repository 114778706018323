import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import firestore from 'firebase/app';
import { remove } from 'remove-accents';

@Injectable({
  providedIn: 'root'
})
export class AddTrackService {
  writers = [];
  foundWriters = [];
  artistsOverview;

  firstWriter;

  constructor(private store: AngularFirestore, private fireFunction: AngularFireFunctions) {
    // this.fireFunction.useFunctionsEmulator('http://localhost:5001');
  }

  updateTracksCount(num: number) {
    this.store
      .collection('overview_docs')
      .doc('overview')
      .update({
        total_tracks: firestore.firestore.FieldValue.increment(num)
      });
  }

  updateWriterTracks(id, num) {
    return this.store
      .collection('artists')
      .doc(id)
      .update({ registered_tracks: firestore.firestore.FieldValue.increment(num) });
  }

  async addTrack(track, addedByclient?: any) {
    console.log(track);
    await this.store.collection('tracks').add(track);
    if (!addedByclient) return;
    const sendAddTrackEmail = this.fireFunction.httpsCallable('sendAddTrackEmail');
    await sendAddTrackEmail({ userId: addedByclient.id, track: track }).toPromise();
  }

  searchForArtist(string) {
    this.foundWriters = [];
    const searchStrings = string.split(' ');
    console.log('search', searchStrings);
    searchStrings.forEach(element => {
      element = remove(element).toUpperCase();
    });
    const aA = Object.keys(this.artistsOverview);
    var allArtists = [];
    aA.forEach(a => {
      console.log(this.artistsOverview[a], a);
      this.artistsOverview[a].forEach(b => {
        allArtists.push(b);
      });
    });

    // var artist_id
    // first_name = remove(first_name).toUpperCase()
    // last_name = remove(last_name).toUpperCase()
    allArtists.forEach(el => {
      const name = remove(el.name).toUpperCase();
      // const ln = remove(el.last_name).toUpperCase()
      var match = false;
      searchStrings.forEach(string_part => {
        if (string_part) {
          console.log(name, string_part);
          const part = remove(string_part).toUpperCase();
          if (name.includes(part)) {
            match = true;
          }
        }
      });
      if (match) {
        this.foundWriters.push(el);
      }
      // if(fn == first_name && ln == last_name){
      //   artist_id = el.id
      // }
    });
    console.log(this.foundWriters);
    // if(!artist_id) artist_id ="missing";
    // console.log(artist_id)
    // // const artIndex = allArtists.findIndex( (a) => {this.normCapitazized(a.first_name) == this.normCapitazized(first_name) && this.normCapitazized(a.last_name) == this.normCapitazized(last_name)})
    // // if(artIndex < 0) return "missing";
    // // else return allArtists[artIndex].id
    // return artist_id
  }

  async loadArtistOverview() {
    console.log('load Artists');
    await this.store
      .collection('artists_overview')
      .ref.where('type', '==', 'artist')
      .get()
      .then(docs => {
        this.artistsOverview = {};
        docs.forEach((doc: any) => {
          this.artistsOverview[doc.id] = doc.data().artists;
          // this.allArtists.concat(doc.data().artists)
        });
        // console.log(this.artistsOverview)
      });
    return;
  }
}
