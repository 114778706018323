import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../services/admin/admin.service';
import { FileHandlerService } from '../services/file-handler/file-handler.service';
import { remove } from 'remove-accents'
import { Router } from '@angular/router';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  tracksType = 'artist'
  tracksState = 'published'

  importedSongs
  viewTrack

  labelOverview
  foundLabels

  searchString

  selectedLabel

  fileSelected = false

  constructor(
    private fileHandler: FileHandlerService,
    public adminService: AdminService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.adminService.getArtistOverview()
    this.getLabels()
  }

  async getLabels(){
    this.labelOverview = await this.adminService.getLabelOverview()
  }

  async handleFile(event){
    this.fileSelected = true
    this.spinner.show('file')
    this.importedSongs = await this.fileHandler.handleFileAll(event, this.tracksType, this.tracksState, this.selectedLabel ? this.selectedLabel.id : undefined)
    this.spinner.hide('file')
    console.log(this.importedSongs)
  }

  trackDetails(track){
    this.viewTrack = track
    this.openModal('singleTrack')
  }

  openModal(modal){
    document.getElementById(modal).classList.add('active')
  } 

  closeModal(modal){
    document.getElementById(modal).classList.remove('active')
  }

  selectLabel(label){
    this.selectedLabel = label
  }

  async saveTracks(){
    const tracks = this.adminService.importedTracks
    this.spinner.show()
    if(tracks instanceof Array){
      var counter = {}
      tracks.forEach(async track => {
        track.collection_ids.forEach(artist_id =>{
          if(counter[artist_id] > 0) {
            counter[artist_id]++
          } else {
            counter[artist_id] = 1
          }
        })
        // track['collection_type'] = 'artist'
        await this.adminService.addTrack(track)
        .then((res)=>console.log(res))
        .catch((err)=>{console.log(err); alert('Error in adding Track:' + track.title)})
      })
      this.spinner.hide()
      const artists = Object.keys(counter)
        artists.forEach(artist =>{
          this.adminService.updateWriterTracks(artist, counter[artist])
        })
      this.adminService.updateTracksCount(tracks.length)
      console.log(counter)
      this.router.navigate(['/admin/dashboard'])
    }
  }


  inputChange(){
    if(this.searchString.length>3){
      this.searchForLabel(this.searchString)
    } else {
      this.foundLabels = []
    }
  }

  searchForLabel(string){
    this.foundLabels = []
    const searchStrings = string.split(' ')
    console.log('search', searchStrings, this.labelOverview)
    searchStrings.forEach(element => {
      element = remove(element).toUpperCase()
    });
    const aA = Object.keys(this.labelOverview)
    var allArtists = []
    aA.forEach((a) => {
      console.log(this.labelOverview[a], a)
      this.labelOverview[a].forEach((b) => {
        allArtists.push(b)
      })
    })
    allArtists.forEach(el =>{
      const name = remove(el.name).toUpperCase()
      var match = false
      searchStrings.forEach(string_part => {
        if(string_part){
          console.log(name, string_part)
        const part = remove(string_part).toUpperCase()
        if(name.includes(part)){
          match = true
        }
        }
      });
      if(match){
        this.foundLabels.push(el)
      }
    })
    console.log(this.foundLabels)
  }

  // handleFile(event){
  //   console.log(event)
  //   const file = <File> event.target.files[0]
  //   const reader = new FileReader()
  //   var split 
  //   reader.readAsText(file)
  //   reader.onload = () => {
  //     console.log(reader.result)
  //     // split = reader.result
  //     split = this.splitCSV(reader.result)
  //     if(split[split.length-1].length<3){
  //       split.pop()
  //     }
  //     console.log(split)
  //     this.arrayToJSON(split)
  //   }
   
  // }

  splitCSV(text){
    const firstSplit = text.split(/\r\n|\n/);
    var secondSplit = []
    firstSplit.forEach(element => {
      secondSplit.push(element.split(','))
    });
    return secondSplit
  }
  // if()

  arrayToJSON(array){
    var outputJson = []
    const headers = array.splice(0,1)[0]
    array.forEach(element => {
      var dataObject = {}
      for(let i=0; i<headers.length; i++){
        dataObject[headers[i]]=element[i]
      }
      outputJson.push(dataObject)
    });
    console.log(outputJson)
    this.extractArtists(outputJson)
  }

  extractArtists(total){
    var artists = []
    // var artist = {
    //   artist_name: null,
    //   first_name: null,
    //   last_name: null,
    //   cae_no: null,
    //   pro: null
    // }
    total.forEach(element => {
      artists.push({
        artist_name: element.account_id,
        first_name: element.w1_first_name,
        last_name: element.w1_surname,
        cae_no: element.w1_cae,
        pro: element.w1_pro
      })
      var iteration = 2
      var fname_string = 'w'+iteration+'_first_name'
      var lname_string = 'w'+iteration+'_surname'
      while ((element[fname_string] || element[lname_string]) && iteration < 10) {
        artists.push({
          artist_name: null,
          first_name: element['w'+iteration+'_first_name'],
          last_name: element['w'+iteration+'_surname'],
          cae_no: element['w'+iteration+'_cae'],
          pro: element['w'+iteration+'_pro']
        })
        iteration++
        fname_string = 'w'+iteration+'_first_name'
        lname_string = 'w'+iteration+'_surname'
      }
    });
    var unique_artists = []
    artists.forEach(artist =>{
      if(!JSON.stringify(unique_artists).includes(JSON.stringify(artist))){
        unique_artists.push(artist)
      }
    })
    console.log('sdfs', unique_artists)
  }

}
