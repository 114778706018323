<div class="wrapper">
    <div class="content">
        <div class="tracks-header">
            <h2>MESSAGES WITH {{adminService.currentArtist.data?.first_name}}</h2>
            <img src="../../../assets/images/dashboard/Skwiglylongline.svg" alt="">
        </div>
        <div #supportChatArea class="messagesWrapper" >
            <div *ngFor="let message of dataService.allMessages" class="messages" #supportLoop>

                <div class="user">

                </div>
                <div class="message">

                    <div class="header" [ngClass]="{'admin': message.type == 'admin'}">
                        <div class="sender"><span *ngIf="message.type == 'client'">{{adminService.currentArtist.data?.first_name}}</span><span *ngIf="message.type == 'admin'">Good Life</span></div>
                        <div class="date">{{message.created_at | date: 'MMM d, y, h:mm'}}</div>
                    </div>
                    
                    <div class="body">
                        <img class="corner-img" src="../../../assets/images/dashboard/Corner.svg" alt="">

                        {{message.body}}
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
        <div class="write">
            <div>
                <textarea name="" id="" [(ngModel)]="messageText"></textarea>
            </div>
            <div class="btn-center">
                <button (click)="sendMessage()">Submit</button>
            </div>
        </div>
    </div>
</div>


