import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../admin/admin.service';

@Injectable({
  providedIn: 'root'
})
export class FileHandlerService {

  constructor(
    private admin: AdminService,
    private spinner: NgxSpinnerService
  ) { }

  handleFile(event){
    console.log(event)
    const file = <File> event.target.files[0]
    const reader = new FileReader()
    var split 
    reader.readAsText(file)
    reader.onload = () => {
      console.log(reader.result)
      split = this.splitCSV(reader.result)
      if(split[split.length-1].length<3){
        split.pop()
      }
      console.log(split)
      return this.arrayToJSON(split)
    }
   
  }

  splitCSV(text){
    const firstSplit = text.split(/\r\n|\n/);
    var secondSplit = []
    firstSplit.forEach(element => {
      secondSplit.push(element.split(','))
    });
    return secondSplit
  }

  arrayToJSON(array){
    var outputJson = []
    const headers = array.splice(0,1)[0]
    array.forEach(element => {
      var dataObject = {}
      for(let i=0; i<headers.length; i++){
        dataObject[headers[i]]=element[i]
      }
      outputJson.push(dataObject)
    });
    console.log(outputJson)
     return this.extractArtists(outputJson)
  }

  extractArtists(total){
    var artists = []
    var tracks = []
    total.forEach(element => {
      var writers = []
      artists.push({
        performer_name: element.account_id,
        first_name: element.w1_first_name,
        last_name: element.w1_surname,
        cae_no: element.w1_cae,
        pro: element.w1_pro
      })
      writers.push({
        performer_name: element.account_id,
        first_name: element.w1_first_name,
        last_name: element.w1_surname,
        cae_no: element.w1_cae,
        pro: element.w1_pro
      })
      var iteration = 2
      var fname_string = 'w'+iteration+'_first_name'
      var lname_string = 'w'+iteration+'_surname'
      while ((element[fname_string] || element[lname_string]) && iteration < 10) {
        artists.push({
          // artist_name: null,
          first_name: element['w'+iteration+'_first_name'],
          last_name: element['w'+iteration+'_surname'],
          performer_name: element['w'+iteration+'_performer_name'],
          cae_no: element['w'+iteration+'_cae'],
          pro: element['w'+iteration+'_pro'],
          // rights_to_collect: element['w'+iteration+'_right_to_collect']
        })
        writers.push({
          // artist_name: null,
          first_name: element['w'+iteration+'_first_name'],
          last_name: element['w'+iteration+'_surname'],
          performer_name: element['w'+iteration+'_performer_name'],
          cae_no: element['w'+iteration+'_cae'],
          pro: element['w'+iteration+'_pro'],
          rights_to_collect: element['w'+iteration+'_right_to_collect']
        })
        iteration++
        fname_string = 'w'+iteration+'_first_name'
        lname_string = 'w'+iteration+'_surname'
      }
      tracks.push({
        title: element.title,
        alt_title: element.alt_title,
        contributing_writers: [],
        writers: writers,
      })
    });
    var unique_artists = []
    artists.forEach(artist =>{
      if(!JSON.stringify(unique_artists).includes(JSON.stringify(artist))){
        unique_artists.push(artist)
      }
    })
    console.log('sdfs', unique_artists)
    return tracks
  }


  handleFileAll(event, type?, state?, label_id?){
    this.spinner.show('file')
    console.log(event)
    const file = <File> event.target.files[0]
    const reader = new FileReader()
    var split 
    reader.readAsText(file)
    reader.onload = () => {
      console.log(reader.result)
      split = this.splitCSV(reader.result)
      if(split[split.length-1].length<3){
        split.pop()
      }
      console.log(split)
      var outputJson = []
      const headers = split.splice(0,1)[0]
      split.forEach(element => {
        var dataObject = {}
        for(let i=0; i<headers.length; i++){
          dataObject[headers[i]]=element[i]
        }
        outputJson.push(dataObject)
      });
      console.log(outputJson)
      var artists = []
    var tracks = []
    outputJson.forEach(element => {
      var writers = []
      // artists.push({
      //   performer_name: element.account_id,
      //   first_name: element.w1_first_name,
      //   last_name: element.w1_surname,
      //   cae_no: element.w1_cae,
      //   pro: element.w1_pro,
      //   share: element.w1_share
      // })
      // writers.push({
      //   performer_name: element.account_id,
      //   first_name: element.w1_first_name,
      //   last_name: element.w1_surname,
      //   cae_no: element.w1_cae,
      //   pro: element.w1_pro,
      //   rights_to_collect: element.w1_right_to_collect
      // })
      var writer_1 = {
        // artist_name: null,
        performer_name: element.account_id.trim(),
        first_name: element.w1_first_name.trim(),
        last_name: element.w1_surname.trim(),
        cae_no: element.w1_cae,
        pro: element.w1_pro,
        rights_to_collect: element.w1_right_to_collect,
        share: (element.w1_share).replace('%','')*1
      }
      if(!writer_1.performer_name) delete writer_1.performer_name;
      if(!writer_1.cae_no) delete writer_1.cae_no;
      if(!writer_1.pro) delete writer_1.pro;
      writers.push(writer_1)
      var iteration = 2
      var fname_string = 'w'+iteration+'_first_name'
      var lname_string = 'w'+iteration+'_surname'
      while ((element[fname_string] || element[lname_string]) && iteration < 10) {
        // artists.push({
        //   // artist_name: null,
        //   first_name: element['w'+iteration+'_first_name'],
        //   last_name: element['w'+iteration+'_surname'],
        //   performer_name: element['w'+iteration+'_performer_name'],
        //   cae_no: element['w'+iteration+'_cae'],
        //   pro: element['w'+iteration+'_pro'],
        //   // rights_to_collect: element['w'+iteration+'_right_to_collect']
        // })
        // writers.push({
        //   // artist_name: null,
        //   first_name: element['w'+iteration+'_first_name'],
        //   last_name: element['w'+iteration+'_surname'],
        //   performer_name: element['w'+iteration+'_performer_name'],
        //   cae_no: element['w'+iteration+'_cae'] ,
        //   pro: element['w'+iteration+'_pro'],
        //   rights_to_collect: element['w'+iteration+'_right_to_collect']
        // })
        var writer = {
          // artist_name: null,
          first_name: element['w'+iteration+'_first_name'] ? element['w'+iteration+'_first_name'].trim() : null,
          last_name: element['w'+iteration+'_surname'].trim(),
          performer_name: element['w'+iteration+'_performer_name'] ? element['w'+iteration+'_performer_name'].trim() : null,
          cae_no: element['w'+iteration+'_cae'] ,
          pro: element['w'+iteration+'_pro'],
          rights_to_collect: element['w'+iteration+'_right_to_collect'],
          share: (element['w'+iteration+'_share']).replace('%','')*1
        }
        if(!writer.performer_name) delete writer.performer_name;
        if(!writer.cae_no) delete writer.cae_no;
        if(!writer.pro) delete writer.pro;
        writers.push(writer)
        iteration++
        fname_string = 'w'+iteration+'_first_name'
        lname_string = 'w'+iteration+'_surname'
      }
      tracks.push({
        title: element.song_title,
        alt_title: element.alt_title,
        // contributing_writers: [],
        writers: writers,
      })
    });
    var unique_artists = []
    artists.forEach(artist =>{
      if(!JSON.stringify(unique_artists).includes(JSON.stringify(artist))){
        unique_artists.push(artist)
      }
    })
    console.log('sdfs', unique_artists)
    tracks.forEach(element => {
      element['created_at'] = new Date(Date.now())
      element['published_at'] = new Date(Date.now())
      element['state'] = state ? state : 'published'
      element['collection_ids'] = []
      if(type == 'artist') {
        element['collection_type'] = 'artist'
        element.writers.forEach(writer => {
          if(writer.rights_to_collect == "Y"){
            writer.rights_to_collect = this.admin.searchForArtist(writer.first_name, writer.last_name)
            console.log(writer.rights_to_collect)
            if(writer.rights_to_collect != "missing") {
              element['collection_ids'].push(writer.rights_to_collect)
            }
          } else {
            writer.rights_to_collect = false
          }
        })
      } else {
        element['collection_type'] = 'label'
        element['collection_ids'].push(label_id)
      }
      
  });
  console.log("tracks", tracks)
  this.admin.importedTracks = tracks
  this.spinner.hide('file')
  return tracks
    //  return this.extractArtists(outputJson)
    //   return this.arrayToJSON(split)
    }
  }

  
  
  /**
   * song = {
   * title:
   * alt_title:
   * collection_ids: []
   * writers: [
   * {
   * first_name
   * last_name
   * performer_name
   * rights_to_collect: false || id || "missing"
   * }
   * ]
   * }
   */


}
