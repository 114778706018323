import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './x_material/material-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './admin/file-upload/file-upload.component';

import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctions, AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { LoginComponent } from './public/login/login.component';
import { ProfileSettingsComponent } from './admin/profile-settings/profile-settings.component';
import { CreateClientComponent } from './admin/create-client/create-client.component';
import { AdminArtistComponent } from './admin/admin-artist/admin-artist.component';
import { AdminSidebarComponent } from './admin/admin-sidebar/admin-sidebar.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { AdminArtistsComponent } from './admin/admin-artists/admin-artists.component';
import { AddTrackComponent } from './add-track/add-track.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClientsComponent } from './clients/clients.component';
import { ClientDashboardComponent } from './clients/client-dashboard/client-dashboard.component';
import { ClientSidebarComponent } from './clients/client-sidebar/client-sidebar.component';
import { AdminNavbarComponent } from './admin/admin-navbar/admin-navbar.component';
import { AdminTracksComponent } from './admin/admin-tracks/admin-tracks.component';
import { ClientTracksComponent } from './clients/client-tracks/client-tracks.component';
import { ClientMessagesComponent } from './clients/client-messages/client-messages.component';
import { AdminThreadsComponent } from './admin/admin-threads/admin-threads.component';
import { AdminMessagesComponent } from './admin/admin-messages/admin-messages.component';
import { LabelComponent } from './label/label.component';
import { ClientAddTrackComponent } from './clients/client-add-track/client-add-track.component';
import { ClientNavbarComponent } from './clients/client-navbar/client-navbar.component';
import { ClientProfileComponent } from './clients/client-profile/client-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    FileUploadComponent,
    LoginComponent,
    ProfileSettingsComponent,
    CreateClientComponent,
    AdminArtistComponent,
    AdminSidebarComponent,
    AdminDashboardComponent,
    AdminComponent,
    AdminArtistsComponent,
    AddTrackComponent,
    ClientsComponent,
    ClientDashboardComponent,
    ClientSidebarComponent,
    AdminNavbarComponent,
    AdminTracksComponent,
    ClientTracksComponent,
    ClientMessagesComponent,
    AdminThreadsComponent,
    AdminMessagesComponent,
    LabelComponent,
    ClientAddTrackComponent,
    ClientNavbarComponent,
    ClientProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    NgxSpinnerModule
    
  ],
  providers: [
    AngularFireFunctions,
    { provide: REGION, useValue: 'europe-west1' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
