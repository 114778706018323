import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  toggleHover(event){
    // console.log(event)
    const el_name = event.srcElement.attributes.name.value;
    // console.log(el_name)
    const elements = document.getElementsByName(el_name);
    elements.forEach(el => {
      el.classList.toggle('element-hover');
    });
  }

}
