<div class="wrapper">
    <div class="content">
        <div>
            <mat-tab-group mat-align-tabs="start">
                <mat-tab label="Artist">
                    <div>
                        <form (ngSubmit)="createArtistClient()" #artistForm="ngForm">
                            <div class="title">
                                First Name
                            </div>
                            <div>
                                <input type="text" name="firstName" [(ngModel)]="userData.first_name" required>
                            </div>
                            <div class="title">
                                Last Name
                            </div>
                            <div>
                                <input type="text" name="lastName" [(ngModel)]="userData.last_name" required>
                            </div>
                            <div class="title">
                                Performer Name
                            </div>
                            <div>
                                <input type="text" name="performerName" [(ngModel)]="userData.performer_name" required>
                            </div>
                            <div class="title">
                                CAE Number
                            </div>
                            <div>
                                <input type="text" name="caeNuber" [(ngModel)]="userData.cae_no"> 
                            </div>
                            <div class="title">
                                PRO
                            </div>
                            <div>
                                <input type="text" name=proOrg [(ngModel)]="userData.pro">
                            </div>
                            <div class="title">
                                Email
                            </div>
                            <div>
                                <input type="text" name="email" [(ngModel)]="user.email" required>
                            </div>
                            <div class="title">
                                Provisional Password
                            </div>
                            <div>
                                <input type="text" name="password" [(ngModel)]="user.password" required>
                            </div>
                            <button style="margin-top: 20px;" type="submit">Create Artist Client</button>
                        </form>
                      </div>
                      <!-- <button (click)="checkClaims()">cehck</button>
                      <button (click)="testRemove()">test</button> -->
                </mat-tab>
                <mat-tab label="Label">
                    <div>
                        <form (ngSubmit)="createLabelClient()" #labelForm="ngForm">
                            <div class="title">
                                First Name
                            </div>
                            <div>
                                <input type="text" name="firstName" [(ngModel)]="labelData.first_name" required>
                            </div>
                            <div class="title">
                                Last Name
                            </div>
                            <div>
                                <input type="text" name="lastName" [(ngModel)]="labelData.last_name" required>
                            </div>
                            <div class="title">
                                Label Name
                            </div>
                            <div>
                                <input type="text" name="performerName" [(ngModel)]="labelData.label_name" required>
                            </div>
                            <div class="title">
                                Email
                            </div>
                            <div>
                                <input type="text" name="email" [(ngModel)]="userLabel.email" required>
                            </div>
                           <div class="title">
                                Provisional Password
                           </div>
                            <div>
                                <input type="text" name="password" [(ngModel)]="userLabel.password" required required>
                            </div>
                            <button style="margin-top: 20px;" type="submit">Create Label Client</button>
                        </form>
                      </div>
                </mat-tab>
                <!-- <mat-tab label="Third">Content 3</mat-tab> -->
              </mat-tab-group>
            <!-- <mat-button-toggle-group name="fontStyle" aria-label="Client Type">
                <mat-button-toggle value="artist">Artist</mat-button-toggle>
                <mat-button-toggle value="label">Label</mat-button-toggle>
        
              </mat-button-toggle-group> -->
            
              <!-- <button (click)="testAdding()">Test to add to over</button> -->
              <!-- <div>
                  <form action="">
                    Label Name
                    <div>
                        <input type="text">
                    </div>
                    Representative Name
                    <div>
                        <input type="text">
                    </div>
                  </form>
              </div> -->
              <div>
        
              </div>
        </div>
    </div>
</div>
<ngx-spinner name="create"></ngx-spinner>
