import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-sidebar',
  templateUrl: './client-sidebar.component.html',
  styleUrls: ['./client-sidebar.component.scss']
})
export class ClientSidebarComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  routeTo(route){
    if(this.router.url.includes('label')){
      this.router.navigate(['/label/', route])
    } else {
      this.router.navigate(['/artist/', route])
    }
  }

  toggleHover(event){
    // console.log(event)
    const el_name = event.srcElement.attributes.name.value;
    // console.log(el_name)
    const elements = document.getElementsByName(el_name);
    elements.forEach(el => {
      el.classList.toggle('element-hover');
    });
  }

}
