<div class="wrapper">
    <div class="content">
        <div class="tracks">
            <div class="create-client">
                <button class="btn-black" (click)="routeTo('add-track')">Add track <img src="../../../assets/images/dashboard/icon_button_addtrack_white.svg" alt=""></button>
            </div>
            <div class="tracks-header">
                <h2>ALL TRACKS</h2>
                <img src="../../../assets/images/dashboard/Skwiglylongline.svg" alt="">
             </div>
            <div class="tracksTable">
                <div class="header">
                    <div class="item title"><div class="title">Title</div></div>
                    <div class="item alt"><div class="title">Alt title</div></div>
                    <div class="item artists"><div class="title">artists</div></div>
                    <div class="item date"><div class="title">published</div></div>
                </div>
               <div class="row" *ngFor="let track of dataService.allTracks" (click)="trackDetails(track)">
                    <div class="title">
                        {{track.title}}
                    </div>
                    <div class="alt">
                        {{track.alt_title}}
                    </div>
                    <div class="artist">
                        {{track.writers[0].first_name + ' ' + track.writers[0].last_name}}
                        <div class="additional" *ngIf="track.writers.length>1">+{{track.writers.length-1}}</div>
                    </div>
                    <div class="date">
                        {{(track.created_at | date:mediumDate)}}
                    </div>
                    <div class="registered">
                        <div *ngIf="track.state == 'not_published'">
                            <img style="height: 30px;" src="../../../assets/images/icon_pending.svg" alt="">
                        </div>
                        <div *ngIf="track.state == 'published'">
                            <img style="height: 30px;" src="../../../assets/images/icon_published.svg" alt="">
                        </div>
                    </div>
               </div> 
            </div>
            <div class="loadMore" *ngIf="dataService.moreTracks">
                <button (click)="dataService.getMoreUserTracks()">Load more tracks</button>
             </div>
        </div>
    </div>
</div>
<div class="modalWrapper" id="singleTrack">
    <div class="modal">
        <div class="content" *ngIf="viewTrack">
            <div class="header">
                <div>
                    {{viewTrack.title}} 
                </div>
                | 
                <div class="type">
                    {{viewTrack.collection_type}}
                </div>
                <div>
                    <mat-icon (click)="closeModal('singleTrack')">highlight_off</mat-icon>
                </div>
            </div>
            <div class="body">
                <div class="publishedSection">
                    <div class="icon">
                        <img src="../../../assets/images/dashboard/icon_dashboard_tracks.svg" alt="">
                    </div>
                    <div class="published">
                        <div class="stateBtn">
                            <div class="state"><span *ngIf="viewTrack.state == 'not_published'">unpublished</span><img *ngIf="viewTrack.state == 'not_published'" src="../../../assets/images/icon_pending.svg" alt=""><span *ngIf="viewTrack.state == 'published'">published</span> <img *ngIf="viewTrack.state == 'published'" src="../../../assets/images/icon_published.svg" alt=""> </div>
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            title
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <div>{{viewTrack.title}}<br>
                            {{viewTrack.alt_title}}
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            artists
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <!-- <div>{{viewTrack.writers[0].performer_name}}
                            <br> {{viewTrack.writers[1]?.performer_name}}
                        </div> -->
                        <div *ngFor="let writer of viewTrack.writers">
                            {{writer.performer_name}}
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            writers
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <div class="line" *ngFor="let writer of viewTrack.writers">
                            {{writer.first_name}} {{writer.last_name}}
                            <div class="ownership">
                                {{writer.share}} %
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

