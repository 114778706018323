<div class="wrapper">
    <div class="content">
        <div class="tracks-header">
            <h2>MESSAGE THREAD</h2>
            <img src="../../../assets/images/dashboard/Skwiglylongline.svg" alt="">
        </div>
        <div>
            <div class="header">
                <div class="item client"><div class="title">Client</div></div>
                <div class="item message"><div class="title">Message</div></div>
                <div class="item status"><div class="title">Status</div></div>
            </div>
            <div *ngFor="let thread of dataService.allThreads" class="thread" (click)="seeThread(thread)">
                <img class="corner-img" src="../../../assets/images/dashboard/Corner.svg" alt="">
                <div class="data client">
                    <div class="client-data">
                        {{thread.first_name}} {{thread.last_name}} 
                    </div>
                </div>
                <div class="data message">
                    <div class="message-data">
                        Message
                    </div>
                </div>
                <div class="data status">
                    <div class="status-content" *ngIf="!thread.unread_messages.client"> 
                        <img src="../../../assets/images/envelope-open-regular.svg" alt="">
                    </div>
                    <div class="number" *ngIf="thread.unread_messages.client && thread.unread_messages.client>0">
                        {{thread.unread_messages.client}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
