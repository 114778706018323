import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import  firestore  from 'firebase/app';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  allArtists
  lastArtist
  moreArtists

  overviewDoc 

  allTracks
  lastTrack
  moreTracks: boolean

  allThreads
  lastThread
  moreThreads: boolean

  allMessages
  lastMessage
  moreMessages: boolean

  unreadCount = 0

  constructor(
    private store: AngularFirestore,
    private snackBar: MatSnackBar
  ) { }

  async getOverview(force?:boolean){
    if(!this.overviewDoc || force){
      await this.store.collection('overview_docs').doc('overview').ref.get()
      .then(over =>{
        this.overviewDoc = over.data()
      })
      .catch(err => alert("ERROR - undefined"))
    }
    return this.overviewDoc
  } 

  // loadOverview(){

  // }

  async getArtists(){
    if(this.allArtists == [] || this.allArtists == null){
      this.allArtists = []
      await this.store.collection('artists').ref.limit(11).get().then((artists) =>{
        // let allArtists = []
        this.lastArtist = artists.docs[artists.docs.length-1]
        if(artists.docs.length>10){
          this.moreArtists = true
        }
        artists.forEach((artist) =>{
          this.allArtists.push(artist.data())
          this.allArtists[this.allArtists.length - 1]['id'] = artist.id
        })
        console.log(this.allArtists)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
    return 
  }

  getMoreArtists(){
    if(this.moreArtists){
      this.store.collection('artists').ref.startAfter(this.lastArtist).limit(11).get().then((artists) =>{
        // let allArtists = []
        this.lastArtist = artists.docs[artists.docs.length-1]
        if(artists.docs.length < 11){
          this.moreArtists = false
        }
        artists.forEach((artist) =>{
          this.allArtists.push(artist.data())
          this.allArtists[this.allArtists.length - 1]['id'] = artist.id
        })
        console.log(this.allArtists)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }


  async getTracks(){
    console.log("init get track", this.allTracks)
    if(!this.allTracks || this.allTracks == null){
      console.log("after check get track")
      this.allTracks = []
      await this.store.collection('tracks').ref.orderBy('created_at', 'desc').limit(11).get().then((tracks) =>{
        this.lastTrack = tracks.docs[tracks.docs.length-1]
        if(tracks.docs.length > 10) {
          this.moreTracks = true
        }
        // let allArtists = []
        tracks.forEach((track) =>{
          this.allTracks.push(track.data())
          this.allTracks[this.allTracks.length - 1]['id'] = track.id
          if(this.allTracks[this.allTracks.length - 1].created_at.seconds){
            console.log("has seconds")
            this.allTracks[this.allTracks.length - 1].created_at = this.allTracks[this.allTracks.length - 1].created_at.seconds *1000 + this.allTracks[this.allTracks.length - 1].created_at.nanoseconds/1000000
          } 
          if(this.allTracks[this.allTracks.length - 1].published_at){
            if(this.allTracks[this.allTracks.length - 1].published_at.seconds){
              console.log("has seconds")
              this.allTracks[this.allTracks.length - 1].published_at = this.allTracks[this.allTracks.length - 1].published_at.seconds *1000 + this.allTracks[this.allTracks.length - 1].published_at.nanoseconds/1000000
            } 
          }
          // else {
          //   console.log("weird date not date")
          //   lastReset = Date.parse(this.overviewDoc.daily_goal.last_reset)
          // }
        })
        if(tracks.docs.length == 11) {
          this.allTracks.pop()
        }
        console.log(this.allTracks)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  loadMoreTracks(){
    if(this.moreTracks){
      this.store.collection('tracks').ref.orderBy('created_at', 'desc').startAt(this.lastTrack).limit(11).get().then((tracks) =>{
        this.lastTrack = tracks.docs[tracks.docs.length-1]
        // let allArtists = []
        if(tracks.docs.length < 11) {
          this.moreTracks = false
        }
        tracks.forEach((track) =>{
          this.allTracks.push(track.data())
          this.allTracks[this.allTracks.length - 1]['id'] = track.id
          if(this.allTracks[this.allTracks.length - 1].created_at.seconds){
            console.log("has seconds")
            this.allTracks[this.allTracks.length - 1].created_at = this.allTracks[this.allTracks.length - 1].created_at.seconds *1000 + this.allTracks[this.allTracks.length - 1].created_at.nanoseconds/1000000
          } 
          if(this.allTracks[this.allTracks.length - 1].published_at){
            if(this.allTracks[this.allTracks.length - 1].published_at.seconds){
              console.log("has seconds")
              this.allTracks[this.allTracks.length - 1].published_at = this.allTracks[this.allTracks.length - 1].published_at.seconds *1000 + this.allTracks[this.allTracks.length - 1].published_at.nanoseconds/1000000
            } 
          }
          // else {
          //   console.log("weird date not date")
          //   lastReset = Date.parse(this.overviewDoc.daily_goal.last_reset)
          // }
        })
        if(tracks.docs.length == 11) {
          this.allTracks.pop()
        }
        console.log(this.allTracks)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  async getThreads(){
    if(!this.allThreads) {
      this.allThreads = []
      await this.store.collection('artists').ref.orderBy('last_message', 'desc').limit(11).get().then((threads) =>{
        // let allArtists = []
        if(threads.docs.length > 10) {
          this.moreThreads = true
        }
        this.lastThread = threads.docs[threads.docs.length-1]
        this.unreadCount = 0
        threads.forEach((thread: any) =>{
          this.allThreads.push(thread.data())
          this.allThreads[this.allThreads.length - 1]['id'] = thread.id
          if(thread.data().unread_messages && thread.data().unread_messages.client){
            this.unreadCount +=  thread.data().unread_messages.client
          }
          
        })
        // if(this.unreadCount > 5)
        if(threads.docs.length == 11) {
          this.allThreads.pop()
        }
        console.log(this.allThreads)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
    return
  }

  getMoreThreads(){
    if(this.moreThreads){
      this.store.collection('artists').ref.orderBy('last_message', 'desc').startAt(this.lastThread).limit(11).get().then((threads) =>{
        // let allArtists = []
        if(threads.docs.length < 11) {
          this.moreThreads = false
        }
        threads.forEach((thread) =>{
          this.allThreads.push(thread.data())
          this.allThreads[this.allThreads.length - 1]['id'] = thread.id
        })
        if(threads.docs.length == 11) {
          this.allThreads.pop()
        }
        console.log(this.allThreads)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  public async getUserMessages(id) {
    if(!this.allMessages) {
    this.store
    .collection('artists').doc(id)
    .collection('messages').ref.orderBy('created_at', 'desc')
    .get()
    .then((messages) =>{
      if(messages){
        this.allMessages = []
        messages.forEach(message => {
          this.allMessages.unshift(message.data())
        })
      }
    })
    }
  }

  markAsRead(id){
    this.store.collection('artists')
      .doc(id)
      .update({"unread_messages.client": 0 })
  }

  async sendMessage(id, message){
    let messageData = {
      body: message,
      created_at: Date.now(),
      type: 'admin',
      // sender_id: this.currentUserID
    }
    return this.store.collection('artists').doc(id).collection('messages').add(messageData)
    .then(() =>{
      this.allMessages.push(messageData)
      return this.store.collection('artists')
      .doc(id)
      .update({"unread_messages.admin": firestore.firestore.FieldValue.increment(1) })
    })
  }

  deleteTrack(id, c_ids) {
    // const track = this.allTracks.find(t => t.id == id)
    // console.log(track)
    return this.store.collection('tracks').doc(id).delete()
    .then(()=>{
      if(this.allTracks) {
        this.allTracks = this.allTracks.filter(t => t.id !== id)
      }
      this.store.collection('overview_docs').doc('overview').update({
        total_tracks: firestore.firestore.FieldValue.increment(-1)
      })
      // track.collection_ids.forEach(id =>{
      //   this.store.collection('artists').doc(id).update({registered_tracks: firestore.firestore.FieldValue.increment(-1)})
      // })
      c_ids.forEach(id =>{
       this.store.collection('artists').doc(id).update({registered_tracks: firestore.firestore.FieldValue.increment(-1)})
      })
      
      this.openSnackBar('Track deleted', 'Ok')
    })
    .catch(()=>{alert("Error in deleting Track")})
  }

  // removeTrackByID(id) {
  //   if(this.allT)
  // }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  
}
