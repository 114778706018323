<div class="wrapper">
    <div class="content">
            <div class="create-client">
               <button class="btn-orange" routerLink="/admin/create-client">Create Client <img src="../../../assets/images/dashboard/icon_button_add_artist_black.svg" alt=""></button>
         </div>
         <div class="tracks-header">
            <h2>ALL CLIENTS OVERVIEW</h2>
            <img src="../../../assets/images/dashboard/Skwiglylongline.svg" alt="">
         </div>
         
        <div *ngIf="dataService.allArtists">
            <!-- <div *ngFor="let artist of dataService.allArtists">
                	{{artist.first_name}} {{artist.last_name}} ({{artist.performer_name}}) - Total Tracks: {{artist.registered_tracks}}
             </div> -->
             <div class="artistTable">
                <div class="header">
                    <div class="item name"><div class="title">Name</div></div>
                    <div class="item performer"><div class="title">Performer</div></div>
                    <div class="item number"><div class="title">Tracks</div></div>
                    <div class="item type"><div class="title">Type</div></div>
                </div>
                <div class="row" *ngFor="let artist of dataService.allArtists" [routerLink]="'/admin/client/artist/'+artist.id">
                     <div class="name">
                        {{artist.first_name}} {{artist.last_name}}
                     </div>
                     <div class="performer">
                        {{artist.performer_name ? artist.performer_name : artist.label_name }}
                     </div>
                     <div class="number">
                        {{artist.registered_tracks}}
                     </div>
                     <div class="type">
                        {{artist.type}}
                     </div>
                </div> 
             </div>
        </div>
        <div class="loadMore" *ngIf="dataService.moreArtists">
           <button (click)="dataService.getMoreArtists()">Load more clients</button>
        </div>
        
    </div>
</div>