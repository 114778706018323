import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddTrackService } from 'src/app/services/add-track/add-track.service';
import { AdminService } from '../services/admin/admin.service';
import { DataService } from '../services/data.service';
import { FileHandlerService } from '../services/file-handler/file-handler.service';

@Component({
  selector: 'app-admin-artist',
  templateUrl: './admin-artist.component.html',
  styleUrls: ['./admin-artist.component.scss']
})
export class AdminArtistComponent implements OnInit {

  importedSongs 

  viewTrack

  constructor(
    public adminService: AdminService,
    public route: ActivatedRoute,
    private fileHandler: FileHandlerService,
    private spinner: NgxSpinnerService,
    private addTrack: AddTrackService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.adminService.getArtistOverview()
    this.adminService.getIndividualArtist(this.route.snapshot.params.id)
    this.adminService.loadArtistTracks(this.route.snapshot.params.id)
  }

  test(){
    console.log(this.importedSongs, this.adminService.importedTracks, this.adminService.artistsOverview, this.adminService.allArtists)
    this.saveTracks(this.adminService.importedTracks)
  }

  async handleFile(event){
    this.importedSongs = this.fileHandler.handleFileAll(event)
    console.log(this.importedSongs)
    
  }

  async saveTracks(tracks){
    if(tracks instanceof Array){
      var counter = {}
      tracks.forEach(track => {
        track.contributing_writers.forEach(artist_id =>{
          if(counter[artist_id] > 0) {
            counter[artist_id]++
          } else {
            counter[artist_id] = 1
          }
        })
        // track['collection_type'] = 'artist'
        this.adminService.addTrack(track)
      })
      const artists = Object.keys(counter)
        artists.forEach(artist =>{
          this.adminService.updateWriterTracks(artist, counter[artist])
        })
      this.adminService.updateTracksCount(tracks.length)
      console.log(counter)
    }
  }

  addFirstWriter() {
    this.addTrack.firstWriter = {
      rights_to_collect: this.adminService.currentArtist.id,
      first_name: this.adminService.currentArtist.data.first_name,
      last_name: this.adminService.currentArtist.data.last_name,
      performer_name: this.adminService.currentArtist.data.performer_name,
      share: null
    }
  }

  async changeTrackState(id, state){
    this.spinner.show()
    if(state == 'not_published') {
      await this.adminService.publishTrack(id)
      .then(() => {
        console.log("track marked as published")
        const index = this.adminService.currentArtist.tracks.findIndex(el => el.id==id)
        this.adminService.currentArtist.tracks[index].state = "published"
        this.viewTrack = this.adminService.currentArtist.tracks[index]
      })
      .catch((err)=>{
        console.log(err)
        alert("ERROR - Track couldn't be published. Please check your connection")
      })
    } else {
      await this.adminService.unpublishTrack(id)
      .then(() => {
        console.log("track marked as unpublished")
        const index = this.adminService.currentArtist.tracks.findIndex(el => el.id==id)
        console.log(index, this.adminService.currentArtist.tracks[index])
        this.adminService.currentArtist.tracks[index].state = "not_published"
        this.viewTrack = this.adminService.currentArtist.tracks[index]
      })
      .catch((err)=>{
        console.log(err)
        alert("ERROR - Track couldn't be unpublished. Please check your connection")
      })
    }
    this.spinner.hide()
  }

  trackDetails(track){
    this.viewTrack = track
    this.openModal('singleTrack')
  }

  openModal(modal){
    document.getElementById(modal).classList.add('active')
  } 

  closeModal(modal){
    document.getElementById(modal).classList.remove('active')
  }

  async deleteTrack(id, c_ids){
    await this.dataService.deleteTrack(id, c_ids)
    this.adminService.currentArtist.tracks = this.adminService.currentArtist.tracks.filter(t => t.id !== id)
    // this.allTracks = this.allTracks.filter(t => t.id !== id)
    this.closeModal('singleTrack')
  }

}
