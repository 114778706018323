<div class="wrapper">
  <div class="cover">
    <img src="../../../assets/images/login/cover.svg" alt="">
  </div >
    <div class="login">
        <div class="card">
          <img class="logo" src="../../../assets/images/login/logo_loginscreen.svg" alt="">
          <h3>Login</h3>
          <form (ngSubmit)="login()" #loginForm="ngForm">
            <div class="input">
              <input placeholder="username" type="email" [(ngModel)]="loginData.email" #emailLogin="ngModel" name="Email" required>
            </div>
            <div class="input">
              <input placeholder="password" type="password" [(ngModel)]="loginData.password" #passwordLogin="ngModel" name="Password" required>
            </div>
            <div>
              <div (click)="openModal('resetModal')" style="cursor: pointer;">
                forgot password?
              </div>
            </div>
            <!-- <mat-form-field>
                <input matInput placeholder="Email" type="email" [(ngModel)]="loginData.email" #emailLogin="ngModel" name="Email" required>
              </mat-form-field>
              <br>
              <mat-form-field>
                <input matInput placeholder="Passwort" type="password" [(ngModel)]="loginData.password" #passwordLogin="ngModel" name="Password" required>
              </mat-form-field> 
              <br> -->
              <button type="submit">log in</button>
            <!-- <button mat-raised-button type="submit">Login</button> -->
        </form>
        </div>
        <!-- <button (click)="makeMeAdmin()">make ad</button>
        <button (click)="custom()">check</button>
        <button (click)="toClient()"> to cleint</button> -->
    </div>
</div>

<div class="modalWrapper" id="resetModal">
  <div class="modal">
      <div class="content">
          <div class="header">
              <div>
                Reset Password
              </div>
          </div>
          <div class="body">
            <form (ngSubmit)="sendReset()" #resetForm="ngForm">
                <div>
                <input type="email" [(ngModel)]="resetEmail" name="email" #emailInput="ngModel" required placeholder="email" email>
              </div>
              <div *ngIf="emailInput.invalid && emailInput.touched" class="alert">
                Enter a valid email
              </div>
              <div class="buttons">
                <button class="btn" type="submit">Send Reset Email</button>
                <button class="btn" type="button" (click)="closeModal('resetModal')">close</button>
              </div>
            </form>
            
            
          </div>
      </div>
  </div>
</div>