<div class="sidebarWrapper">
    <div class="logo">
        <img src="../../../assets/images/sidebar/logo_side_bar.svg" alt="">
    </div>
    <div class="menuWrapper">
        <div class="menu">
            <div class="menuPoint" name="dashboard" (mouseenter)="toggleHover($event)" (mouseleave)="toggleHover($event)" routerLink="/admin/dashboard">
                <div class="icon">
                    <img src="../../../assets/images/sidebar/icon_sidebar_dashboard.svg" alt="">
                </div>
            </div>
            <div class="menuPoint" name="clients" (mouseenter)="toggleHover($event)" (mouseleave)="toggleHover($event)" routerLink="/admin/all-artists">
                <div class="icon">
                    <img src="../../../assets/images/sidebar/icon_sidebar_clients.svg" alt="">
                </div>
            </div>
            <div class="menuPoint" name="tracks" (mouseenter)="toggleHover($event)" (mouseleave)="toggleHover($event)" routerLink="/admin/all-tracks">
                <div class="icon">
                    <img src="../../../assets/images/sidebar/icon_sidebar_tracks.svg" alt="">
                </div>
            </div>
            <div class="menuPoint" name="messages" (mouseenter)="toggleHover($event)" (mouseleave)="toggleHover($event)" routerLink="/admin/messages">
                <div class="icon">
                    <img src="../../../assets/images/sidebar/icon_sidebar_messages.svg" alt="">
                </div>
            </div>
        </div>
        <div class="slide">
            <div class="menuPoint" name="dashboard" (mouseenter)="toggleHover($event)" (mouseleave)="toggleHover($event)" routerLink="/admin/dashboard">
                Dashboard
            </div>
            <div class="menuPoint" name="clients" (mouseenter)="toggleHover($event)" (mouseleave)="toggleHover($event)" routerLink="/admin/all-artists">
                Clients
            </div>
            <div class="menuPoint" name="tracks" (mouseenter)="toggleHover($event)" (mouseleave)="toggleHover($event)" routerLink="/admin/all-tracks">
                Tracks
            </div>
            <div class="menuPoint" name="messages" (mouseenter)="toggleHover($event)" (mouseleave)="toggleHover($event)" routerLink="/admin/messages">
                Messages
            </div>
        </div>
    </div>
</div>