import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {

  constructor(
    private store: AngularFireStorage
  ) { }

  uploadPercent
  downloadURL

  ngOnInit(): void {
  }

  handleFile(event){
    const file = <File> event.target.files[0]
    const filePath = '/profile';
    const fileRef = this.store.ref(filePath);
    const upload = this.store.upload(filePath,file)
    this.uploadPercent = upload.percentageChanges()
    upload.snapshotChanges().pipe(
      finalize(() => this.downloadURL = fileRef.getDownloadURL() )
   )
  .subscribe()
  }

}
