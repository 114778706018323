import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {
  currentUserID;

  currentUserData;

  allTracks;
  lastTrack;
  moreTracks: boolean;

  allMessages;

  constructor(private store: AngularFirestore, private auth: AngularFireAuth, private router: Router, private fireFunction: AngularFireFunctions) {
    // this.fireFunction.useFunctionsEmulator('http://localhost:5001');
  }

  async getUserData() {
    if (!this.currentUserID) {
      const res = await this.auth.currentUser;
      this.currentUserID = res.uid;
      console.log(res);
    }
    await this.store
      .collection('artists')
      .doc(this.currentUserID)
      .get()
      .toPromise()
      .then(res => {
        this.currentUserData = res.data();
      })
      .catch(() => {
        alert('Unexpected Error - Please check your connection!');
      });
    return;
  }

  async updateUserName({ first_name, last_name, cae_no, pro }) {
    try {
      const res = await this.store.collection('artists').doc(this.currentUserID).update({ first_name, last_name, cae_no, pro });
      console.log(res);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async updateEmail({ email }) {
    try {
      const authEmailRes = await (await this.auth.currentUser).updateEmail(email);
      console.log(authEmailRes);
      const res = await this.store.collection('artists').doc(this.currentUserID).update({ email });
      (await this.auth.currentUser).sendEmailVerification();
      return true;
    } catch (error) {
      return error.message;
    }
  }
  async updatePassword() {
    try {
      await this.auth.sendPasswordResetEmail(this.currentUserData.email);
      return 'Password reset email has been sent to your registered email id';
    } catch (error) {
      return error.message;
    }
  }

  async getUserSpecificTracks() {
    if (!this.allTracks) {
      if (!this.currentUserID) {
        await this.auth.currentUser.then(res => {
          console.log(res.uid);
          this.currentUserID = res.uid;
        });
      }
      this.store
        .collection('tracks')
        .ref.where('collection_ids', 'array-contains', this.currentUserID)
        .orderBy('created_at', 'desc')
        .limit(11)
        .get()
        .then(tracks => {
          this.lastTrack = tracks.docs[tracks.docs.length - 1];
          if (tracks.docs.length > 10) {
            this.moreTracks = true;
          }
          this.allTracks = [];
          tracks.forEach(track => {
            this.allTracks.push(track.data());
            this.allTracks[this.allTracks.length - 1]['id'] = track.id;
            if (this.allTracks[this.allTracks.length - 1].created_at.seconds) {
              console.log('has seconds');
              this.allTracks[this.allTracks.length - 1].created_at =
                this.allTracks[this.allTracks.length - 1].created_at.seconds * 1000 +
                this.allTracks[this.allTracks.length - 1].created_at.nanoseconds / 1000000;
            }
            if (this.allTracks[this.allTracks.length - 1].published_at) {
              if (this.allTracks[this.allTracks.length - 1].published_at.seconds) {
                console.log('has seconds');
                this.allTracks[this.allTracks.length - 1].published_at =
                  this.allTracks[this.allTracks.length - 1].published_at.seconds * 1000 +
                  this.allTracks[this.allTracks.length - 1].published_at.nanoseconds / 1000000;
              }
            }
          });
          console.log(this.allTracks);
        });
    }
  }

  async sendUserContract() {
    try {
      if (this.currentUserData.contract?.id) {
        return await this.fireFunction
          .httpsCallable('resendContractEmail')({ document: this.currentUserData.contract.id, uid: this.currentUserID })
          .toPromise();
      } else {
        return await this.fireFunction.httpsCallable('sendContractEmail')({ email: this.currentUserData.email, uid: this.currentUserID }).toPromise();
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getMoreUserTracks() {
    if (!this.currentUserID) {
      await this.auth.currentUser.then(res => {
        console.log(res.uid);
        this.currentUserID = res.uid;
      });
    }
    this.store
      .collection('tracks')
      .ref.where('collection_ids', 'array-contains', this.currentUserID)
      .orderBy('created_at', 'desc')
      .startAt(this.lastTrack)
      .limit(11)
      .get()
      .then(tracks => {
        this.lastTrack = tracks.docs[tracks.docs.length - 1];
        if (tracks.docs.length < 11) {
          this.moreTracks = false;
        }
        tracks.forEach(track => {
          this.allTracks.push(track.data());
          this.allTracks[this.allTracks.length - 1]['id'] = track.id;
          if (this.allTracks[this.allTracks.length - 1].created_at.seconds) {
            console.log('has seconds');
            this.allTracks[this.allTracks.length - 1].created_at =
              this.allTracks[this.allTracks.length - 1].created_at.seconds * 1000 +
              this.allTracks[this.allTracks.length - 1].created_at.nanoseconds / 1000000;
          }
          if (this.allTracks[this.allTracks.length - 1].published_at) {
            if (this.allTracks[this.allTracks.length - 1].published_at.seconds) {
              console.log('has seconds');
              this.allTracks[this.allTracks.length - 1].published_at =
                this.allTracks[this.allTracks.length - 1].published_at.seconds * 1000 +
                this.allTracks[this.allTracks.length - 1].published_at.nanoseconds / 1000000;
            }
          }
        });
        console.log(this.allTracks);
      });
  }

  async sendMessage(message) {
    if (!this.currentUserID) {
      await this.auth.currentUser.then(res => {
        if (res) {
          this.currentUserID = res.uid;
        } else {
          this.router.navigate(['/login']);
        }
      });
    }
    let messageData = {
      body: message,
      created_at: Date.now(),
      type: 'client',
      sender_id: this.currentUserID
    };
    return this.store
      .collection('artists')
      .doc(this.currentUserID)
      .collection('messages')
      .add(messageData)
      .then(() => {
        let update;
        if (this.currentUserData && this.currentUserData.unread_messages) {
          this.currentUserData.unread_messages.client++;
          update = { unread_messages: this.currentUserData.unread_messages, admin: 0 };
        } else {
          update = { unread_messages: { client: 1, admin: 0 } };
        }
        update['last_message'] = Date.now();
        this.allMessages.push(messageData);

        return this.store.collection('artists').doc(this.currentUserID).update(update);
      });
  }

  async getUserMessages() {
    if (!this.currentUserID) {
      await this.auth.currentUser.then(res => {
        if (res) {
          this.currentUserID = res.uid;
        } else {
          this.router.navigate(['/login']);
        }
      });
    }
    this.store
      .collection('artists')
      .doc(this.currentUserID)
      .collection('messages')
      .ref.orderBy('created_at', 'desc')
      .get()
      .then(messages => {
        if (messages) {
          this.allMessages = [];
          messages.forEach(message => {
            this.allMessages.unshift(message.data());
          });
        }
        this.store.collection('artists').doc(this.currentUserID).update({ 'unread_messages.admin': 0 });
      });
  }
}
