import { Component, OnInit } from '@angular/core';
import { ClientDataService } from '../services/data-service/client-data.service';
import clone from 'just-clone';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {
  userData = null;
  firstNameError = '';
  lastNameError = '';
  emailError = '';
  caeError = '';
  proError = '';
  oldPass = '';
  oldPassError = '';
  newPass = '';
  newPassError = '';
  confirmPass = '';
  confirmPassError = '';

  constructor(public dataService: ClientDataService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.getUserData();
  }
  getUserData() {
    this.spinner.show();
    this.dataService.getUserData().then(_ => {
      this.resetData();
      this.spinner.hide();
      console.log(this.dataService.currentUserData);
    });
  }

  async updateName(event) {
    event.preventDefault();
    if (!this.userData.first_name.trim()) return (this.firstNameError = 'First Name is required');
    else this.firstNameError = '';
    if (!this.userData.last_name.trim()) return (this.lastNameError = 'First Name is required');
    else this.lastNameError = '';
    this.spinner.show();
    const res = await this.dataService.updateUserName(this.userData);
    if (!res) return alert('Name update failed!!!');
    this.getUserData();
    this.closeModal('namePop');
  }
  async updateCae(event) {
    event.preventDefault();
    if (!this.userData.cae_no.trim()) return (this.caeError = 'CAE Number is required');
    else this.caeError = '';
    this.spinner.show();
    const res = await this.dataService.updateUserName(this.userData);
    if (!res) return alert('CAE Number update failed!!!');
    this.getUserData();
    this.closeModal('caePop');
  }
  async updatePro(event) {
    event.preventDefault();
    if (!this.userData.pro.trim()) return (this.proError = 'Pro is required');
    else this.proError = '';
    this.spinner.show();
    const res = await this.dataService.updateUserName(this.userData);
    if (!res) return alert('Pro update failed!!!');
    this.getUserData();
    this.closeModal('proPop');
  }

  async updateEmail(event) {
    event.preventDefault();
    const reg =
      /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/;
    if (!this.userData.email.trim()) return (this.emailError = 'Email address is required');
    else if (!reg.test(this.userData.email)) return (this.emailError = 'Please provide Valid email address');
    else this.emailError = '';
    this.spinner.show();
    const res = await this.dataService.updateEmail(this.userData);
    this.closeModal('emailPop');
    if (res !== true) {
      this.resetData();
      this.spinner.hide();
      return alert(res);
    }
    this.getUserData();
  }

  async updatePass() {
    this.spinner.show();
    const res = await this.dataService.updatePassword();
    alert(res);
    this.spinner.hide();
  }

  closeNamePop() {
    this.closeModal('namePop');
  }

  closeEmailPop() {
    this.closeModal('emailPop');
  }
  closePassPop() {
    this.closeModal('passwordPop');
  }

  openModal(modal) {
    document.getElementById(modal).classList.add('active');
  }

  closeModal(modal) {
    document.getElementById(modal).classList.remove('active');
    this.resetData();
  }
  resetData() {
    this.firstNameError = '';
    this.lastNameError = '';
    this.emailError = '';
    this.oldPass = '';
    this.oldPassError = '';
    this.newPass = '';
    this.newPassError = '';
    this.confirmPass = '';
    this.confirmPassError = '';
    this.userData = clone(this.dataService.currentUserData);
  }
}
