<div class="wrapper">
  <div class="content">
    <div *ngIf="userData" class="glp-p-v">
      <div>
        <h1>Hello {{ dataService.currentUserData?.first_name }}!</h1>
      </div>
      <div class="glp-p-v-f">
        <div class="glp-p-v-f-i">
          <h3>Name:</h3>
          <p>{{ userData.first_name }} {{ userData.last_name }}</p>
        </div>
        <button (click)="openModal('namePop')">Update</button>
      </div>
      <div class="glp-p-v-f">
        <div class="glp-p-v-f-i">
          <h3>CAE Number:</h3>
          <p>{{ userData.cae_no }}</p>
        </div>
        <button (click)="openModal('caePop')">Update</button>
      </div>
      <div class="glp-p-v-f">
        <div class="glp-p-v-f-i">
          <h3>PRO:</h3>
          <p>{{ userData.pro }}</p>
        </div>
        <button (click)="openModal('proPop')">Update</button>
      </div>
      <div class="glp-p-v-f">
        <div class="glp-p-v-f-i">
          <h3>Email:</h3>
          <p>{{ userData.email }}</p>
        </div>
        <button (click)="openModal('emailPop')">Update</button>
      </div>
      <div class="glp-p-v-f">
        <div class="glp-p-v-f-i">
          <h3>Password:</h3>
          <p>********</p>
        </div>
        <button (click)="openModal('passwordPop')">Update</button>
      </div>
    </div>
  </div>
</div>
<div class="modalWrapper" id="emailPop">
  <div class="modal">
    <div class="content">
      <span class="glp-p-v-m-c" (click)="closeModal('emailPop')">X</span>
      <div class="glp-p-v-m-h">Change Email</div>
      <div class="glp-p-v-m-bo">
        <form *ngIf="userData" (ngSubmit)="updateEmail($event)" #emailForm="ngForm">
          <div class="glp-p-v-m-r">
            <div class="title">Email</div>
            <div class="glp-p-v-m-i">
              <input type="text" name="email" [(ngModel)]="userData.email" (input)="emailError = ''" [ngClass]="emailError ? 'error' : ''" />
              <span>{{ emailError }}</span>
            </div>
          </div>
          <div>
            <button class="glp-p-v-m-b" type="submit">Update Email</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modalWrapper" id="proPop">
  <div class="modal">
    <div class="content">
      <span class="glp-p-v-m-c" (click)="closeModal('proPop')">X</span>
      <div class="glp-p-v-m-h">Change Pro</div>
      <div class="glp-p-v-m-bo">
        <form *ngIf="userData" (ngSubmit)="updatePro($event)" #proForm="ngForm">
          <div class="glp-p-v-m-r">
            <div class="title">Pro</div>
            <div class="glp-p-v-m-i">
              <input type="text" name="pro" [(ngModel)]="userData.pro" [ngClass]="proError ? 'error' : ''" (input)="proError = ''" />
              <span>{{ proError }}</span>
            </div>
          </div>
          <button class="glp-p-v-m-b" type="submit">Update Pro</button>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modalWrapper" id="caePop">
  <div class="modal">
    <div class="content">
      <span class="glp-p-v-m-c" (click)="closeModal('caePop')">X</span>
      <div class="glp-p-v-m-h">Change CAE Number</div>
      <div class="glp-p-v-m-bo">
        <form *ngIf="userData" (ngSubmit)="updateCae($event)" #caeForm="ngForm">
          <div class="glp-p-v-m-r">
            <div class="title">CAE Number</div>
            <div class="glp-p-v-m-i">
              <input type="text" name="cae" [(ngModel)]="userData.cae_no" [ngClass]="caeError ? 'error' : ''" (input)="caeError = ''" />
              <span>{{ caeError }}</span>
            </div>
          </div>
          <button class="glp-p-v-m-b" type="submit">Update CAE Number</button>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modalWrapper" id="namePop">
  <div class="modal">
    <div class="content">
      <span class="glp-p-v-m-c" (click)="closeModal('namePop')">X</span>
      <div class="glp-p-v-m-h">Change Name</div>
      <div class="glp-p-v-m-bo">
        <form *ngIf="userData" (ngSubmit)="updateName($event)" #nameForm="ngForm">
          <div class="glp-p-v-m-r">
            <div class="title">First Name</div>
            <div class="glp-p-v-m-i">
              <input
                type="text"
                name="first_name"
                [(ngModel)]="userData.first_name"
                [ngClass]="firstNameError ? 'error' : ''"
                (input)="firstNameError = ''"
              />
              <span>{{ firstNameError }}</span>
            </div>
          </div>
          <div class="glp-p-v-m-r">
            <div class="title">Last Name</div>
            <div class="glp-p-v-m-i">
              <input
                type="text"
                name="last_name"
                [(ngModel)]="userData.last_name"
                [ngClass]="lastNameError ? 'error' : ''"
                (input)="lastNameError = ''"
              />
              <span>{{ lastNameError }}</span>
            </div>
          </div>
          <button class="glp-p-v-m-b" type="submit">Update Name</button>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modalWrapper" id="passwordPop">
  <div class="modal">
    <div class="content">
      <span class="glp-p-v-m-c" (click)="closeModal('passwordPop')">X</span>
      <div class="glp-p-v-m-h">Change Name</div>
      <div class="glp-p-v-m-bo">
        <p style="text-align: center">
          If you want to reset password then please click the button below. It will send a
          <b>Reset Password Link</b>
          to your registered email id.
        </p>
        <button class="glp-p-v-m-b" (click)="updatePass()">Reset Password</button>
      </div>
    </div>
  </div>
</div>
