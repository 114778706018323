import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../services/admin/admin.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-admin-tracks',
  templateUrl: './admin-tracks.component.html',
  styleUrls: ['./admin-tracks.component.scss']
})
export class AdminTracksComponent implements OnInit {

  viewTrack

  constructor(
    public dataService: DataService,
    private adminService: AdminService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.dataService.getTracks()
  }

  async changeTrackState(id, state){
    this.spinner.show()
    if(state == 'not_published') {
      await this.adminService.publishTrack(id)
      .then(() => {
        console.log("track marked as published")
        const index = this.dataService.allTracks.findIndex(el => el.id==id)
        this.dataService.allTracks[index].state = "published"
        this.viewTrack = this.dataService.allTracks[index]
      })
      .catch((err)=>{
        console.log(err)
        alert("ERROR - Track couldn't be published. Please check your connection")
      })
    } else {
      await this.adminService.unpublishTrack(id)
      .then(() => {
        console.log("track marked as unpublished")
        const index = this.dataService.allTracks.findIndex(el => el.id==id)
        console.log(index, this.dataService.allTracks[index])
        this.dataService.allTracks[index].state = "not_published"
        this.viewTrack = this.dataService.allTracks[index]
      })
      .catch((err)=>{
        console.log(err)
        alert("ERROR - Track couldn't be unpublished. Please check your connection")
      })
    }
    this.spinner.hide()
  }

  trackDetails(track){
    this.viewTrack = track
    this.openModal('singleTrack')
  }

  openModal(modal){
    document.getElementById(modal).classList.add('active')
  } 

  closeModal(modal){
    document.getElementById(modal).classList.remove('active')
  }

}
