import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService {

  constructor(
    private fireAuth: AngularFireAuth,
    private  router: Router
  ) { }


  canActivate(): Observable<boolean>{
    // return this.fireAuth.authState.pipe(
    //   map(user => {
    //       if(user) {
    //         return true
    //       }
    //       else{
    //         this.router.navigate(['/login'])
    //         return false;
    //       } 
    //   })
    // )
    return this.fireAuth.idTokenResult.pipe(
      map(tokens=>{
          console.log(tokens)
          if(tokens && tokens.claims.superadmin) {
          console.log("gucci")
          return true
          } else {
            console.log("problemo")
            this.router.navigate(['/login'])
            return false;
          } 
      })
    )
  }

  
}
