import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/public/services/auth.service';
import { ClientDataService } from '../services/data-service/client-data.service';

@Component({
  selector: 'app-client-navbar',
  templateUrl: './client-navbar.component.html',
  styleUrls: ['./client-navbar.component.scss']
})
export class ClientNavbarComponent implements OnInit {

  constructor(
    public clientData: ClientDataService,
    private router: Router,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.clientData.getUserData()
  }
  routeTo(route){
    if(this.router.url.includes('label')){
      this.router.navigate(['/label/', route])
    } else {
      this.router.navigate(['/artist/', route])
    }
  }

}
