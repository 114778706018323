import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { ClientDataService } from '../../clients/services/data-service/client-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    public fireAuth: AngularFireAuth,
    private router: Router,
    private fireFunction: AngularFireFunctions,
    private clientService: ClientDataService
  ) {
    // this.fireFunction.useFunctionsEmulator('http://localhost:5001');
  }

  public async login(loginData) {
    return this.fireAuth
      .signInWithEmailAndPassword(loginData.email, loginData.password)
      .then(async res => {
        let claims = await this.checkCustomClaims();
        if (!claims.superadmin) {
          console.log(claims);
          if (!res.user.emailVerified) {
            const conf = confirm('You need to verify your email first. Do you want us to resend verification email?');
            if (conf) {
              return await this.fireFunction.httpsCallable('sendEmailVerification')({ email: loginData.email, userId: res.user.uid }).toPromise();
            } else return;
          } else if (!claims.notFirstTime) {
            this.welcomeUser(res.user);
          }
          // res.user.sendEmailVerification();
        }

        if (claims.superadmin) {
          this.router.navigate(['/admin/dashboard']);
        } else {
          if (claims.label) {
            this.router.navigate(['/label/dashboard']);
          } else {
            this.router.navigate(['/artist/dashboard']);
            this.clientService.currentUserID = res.user.uid;
          }
        }
      })
      .catch(err => {
        console.log(err);
        if (err.code == 'auth/wrong-password') {
          alert('The password you entered is wrong!');
        } else {
          alert('Login unsuccessful');
        }
      });
  }

  resetPassword(email) {
    return this.fireAuth.sendPasswordResetEmail(email);
  }

  public async welcomeUser(user) {
    const sendWelcomeEmail = this.fireFunction.httpsCallable('sendWelcomeEmail');
    await sendWelcomeEmail({ email: user.email, userId: user.uid }).toPromise();
  }

  public makeSuperAdmin() {
    const makeAdmin = this.fireFunction.httpsCallable('makeAdmin');
    makeAdmin({})
      .toPromise()
      .then(res => {
        // if(this.checkCustomClaims.the.claims.superadmin)
      })
      .catch(err => {
        console.log(err);
      });
  }

  public async checkCustomClaims() {
    console.log('claims');
    // this.fireAuth.
    // this.fireAuth.auth.currentUser.getIdTokenResult().then((res) => {console.log(res)})
    // this.fireAuth.authState.toPromise().then((res) => {console.log(res)})
    let claims;
    await (await this.fireAuth.currentUser).getIdTokenResult().then(res => {
      console.log(res);
      claims = res.claims;
    });
    return claims;
    // await (await this.fireAuth.currentUser)
    // this.fireAuth.idToken.toPromise().then((res) => {console.log(res)})
    // this.fireAuth.credential.toPromise().then((res) => {console.log(res)})
    // console.log(this.fireAuth.credential)
  }

  logOut() {
    this.fireAuth.signOut();
    this.router.navigate(['/login']);
  }
}
