<div class="wrapper">
  <div class="content">
    <div class="header">
      <h1 style="text-align: center">Hello {{ dataService.currentUserData?.first_name }}!</h1>
      <button class="btn-orange" (click)="routeTo('add-track')">
        Add track <img src="../../../assets/images/dashboard/icon_button_addtrack_white.svg" alt="" />
      </button>
    </div>
    <!-- <div class="create-client">
        </div> -->
    <div class="overview">
      <div class="info" (click)="routeTo('my-tracks')">
        <div class="icon">
          <img src="../../../assets/images/dashboard/icon_dashboard_tracks.svg" alt="" />
        </div>
        <div class="title">total tracks</div>
        <div class="number">
          <h1>{{ dataService.currentUserData?.registered_tracks }}</h1>
        </div>
      </div>
      <div class="info" (click)="routeTo('messages')">
        <div class="icon">
          <img src="../../../assets/images/dashboard/icon_dashboard_messages.svg" alt="" />
        </div>
        <div class="title">messages</div>
        <div class="number">
          <h1>{{ dataService.currentUserData?.unread_messages.admin ? dataService.currentUserData?.unread_messages.admin : 0 }}</h1>
        </div>
      </div>
    </div>
    <!-- <button (click)="show">tst spinner</button> -->
    <div class="tracks">
      <div class="tracks-header">
        <h2>LATEST TRACKS</h2>
        <img src="../../../assets/images/dashboard/Skwiglylongline.svg" alt="" />
      </div>
      <div class="tracksTable">
        <div class="header">
          <div class="item title">
            <div class="title">Title</div>
          </div>
          <div class="item alt">
            <div class="title">Alt title</div>
          </div>
          <div class="item artists">
            <div class="title">writers</div>
          </div>
          <div class="item date">
            <div class="title">published</div>
          </div>
        </div>
        <div class="row" *ngFor="let track of dataService.allTracks" (click)="trackDetails(track)">
          <div class="title">
            {{ track.title }}
          </div>
          <div class="alt">
            {{ track.alt_title }}
          </div>
          <div class="artist">
            {{ track.writers[0].first_name + " " + track.writers[0].last_name }}
            <div class="additional" *ngIf="track.writers.length > 1">+{{ track.writers.length - 1 }}</div>
          </div>
          <div>
            {{ track.created_at | date: mediumDate }}
          </div>
          <div class="registered">
            <div *ngIf="track.state == 'not_published'">
              <img style="height: 30px" src="../../../assets/images/icon_pending.svg" alt="" />
            </div>
            <div *ngIf="track.state == 'published'">
              <img style="height: 30px" src="../../../assets/images/icon_published.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="loadMore" *ngIf="dataService.moreTracks">
        <button (click)="dataService.getMoreUserTracks()">Load more tracks</button>
      </div>
    </div>
  </div>
</div>
<div class="modalWrapper" id="singleTrack">
  <div class="modal">
    <div class="content" *ngIf="viewTrack">
      <div class="header">
        <div>
          {{ viewTrack.title }}
        </div>
        |
        <div class="type">
          {{ viewTrack.collection_type }}
        </div>
        <div>
          <mat-icon (click)="closeModal('singleTrack')">highlight_off</mat-icon>
        </div>
      </div>
      <div class="body">
        <div class="publishedSection">
          <div class="icon">
            <img src="../../../assets/images/dashboard/icon_dashboard_tracks.svg" alt="" />
          </div>
          <div class="published">
            <div class="stateBtn">
              <div class="state">
                <span *ngIf="viewTrack.state == 'not_published'">unpublished</span
                ><img *ngIf="viewTrack.state == 'not_published'" src="../../../assets/images/icon_pending.svg" alt="" /><span
                  *ngIf="viewTrack.state == 'published'"
                  >published</span
                >
                <img *ngIf="viewTrack.state == 'published'" src="../../../assets/images/icon_published.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="data">
          <div class="title">
            <div class="text">title</div>
            <img src="../../../assets/images/dashboard/Corner.svg" alt="" />
          </div>
          <div class="dataContent">
            <div>
              {{ viewTrack.title }}<br />
              {{ viewTrack.alt_title }}
            </div>
          </div>
        </div>
        <div class="data">
          <div class="title">
            <div class="text">artists</div>
            <img src="../../../assets/images/dashboard/Corner.svg" alt="" />
          </div>
          <div class="dataContent">
            <!-- <div>{{viewTrack.writers[0].performer_name}}
                            <br> {{viewTrack.writers[1]?.performer_name}}
                        </div> -->
            <div *ngFor="let writer of viewTrack.writers">
              {{ writer.performer_name }}
            </div>
          </div>
        </div>
        <div class="data">
          <div class="title">
            <div class="text">writers</div>
            <img src="../../../assets/images/dashboard/Corner.svg" alt="" />
          </div>
          <div class="dataContent">
            <div class="line" *ngFor="let writer of viewTrack.writers">
              {{ writer.first_name }} {{ writer.last_name }}
              <div class="ownership">{{ writer.share }} %</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="gbp-contract-wrp" *ngIf="contract.show">
  <div class="gbp-contract">
    <div class="gbp-contract-head">
      <h3>Contract Info</h3>
    </div>
    <div class="gbp-contract-body">
      <ng-container *ngIf="!contract.id && !contractSent">
        <p class="gbp-contract-message">
          To use our services, you need to sign the contract. Please click the button below, if you want us to send the doument to you email.
        </p>
        <button class="gbp-contract-btn" (click)="sendContract()">
          {{ contract.id ? "Resend Contract" : "Send Contract" }}
        </button>
      </ng-container>
      <ng-container *ngIf="contract.id || contractSent">
        <p class="gbp-contract-message">The contract has been sent to your email. Please sign it to use the app.</p>
      </ng-container>
    </div>
  </div>
</div>
