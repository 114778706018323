import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientDataService } from '../services/data-service/client-data.service';

@Component({
  selector: 'app-client-tracks',
  templateUrl: './client-tracks.component.html',
  styleUrls: ['./client-tracks.component.scss']
})
export class ClientTracksComponent implements OnInit {

  viewTrack

  constructor(
    public dataService: ClientDataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dataService.getUserSpecificTracks()
  }


  trackDetails(track){
    this.viewTrack = track
    this.openModal('singleTrack')
  }

  openModal(modal){
    document.getElementById(modal).classList.add('active')
  } 

  closeModal(modal){
    document.getElementById(modal).classList.remove('active')
  }

  routeTo(route){
    if(this.router.url.includes('label')){
      this.router.navigate(['/label/', route])
    } else {
      this.router.navigate(['/artist/', route])
    }
  }

}
