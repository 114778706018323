import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../services/admin/admin.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-admin-messages',
  templateUrl: './admin-messages.component.html',
  styleUrls: ['./admin-messages.component.scss']
})
export class AdminMessagesComponent implements OnInit, AfterViewInit, OnDestroy {

  messageText

  @ViewChildren('supportLoop') messages: QueryList<any>;
  @ViewChild('supportChatArea') scrollFrame: ElementRef;
  supportMsgContainer

  constructor(
    private route: ActivatedRoute,
    public dataService: DataService,
    public adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.dataService.getUserMessages(this.route.snapshot.params.id)
    this.dataService.markAsRead(this.route.snapshot.params.id)
    this.adminService.getIndividualArtist(this.route.snapshot.params.id)
  }

  ngAfterViewInit(){
    this.supportMsgContainer = this.scrollFrame.nativeElement
    this.supportMsgContainer.scrollTo(0,this.supportMsgContainer.scrollHeight)
    this.messages.changes.subscribe(() => {
      this.supportMsgContainer.scrollTo(0,this.supportMsgContainer.scrollHeight)
    })
  }
  

  sendMessage(){
    this.dataService.sendMessage(this.route.snapshot.params.id,this.messageText).then(res => { console.log(res); this.messageText = null;})

  }

  ngOnDestroy(){
    this.dataService.allMessages = null
  }

}
