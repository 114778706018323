import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddTrackService } from '../services/add-track/add-track.service'

@Component({
  selector: 'app-add-track',
  templateUrl: './add-track.component.html',
  styleUrls: ['./add-track.component.scss']
})
export class AddTrackComponent implements OnInit {

  writers = []
  foundWriters = []

  searchString = ""


  track = {
    title: null,
    alt_title: null,
    writers: [],
    collection_ids: [],
    collection_type: "artist", //artist or label ->
    state: "not_published",
    created_at: null,
    published_at: null
  }

  addWriterObject = {
    rights_to_collect: false,
    first_name: null,
    last_name: null,
    performer_name: null,
    share: null
  }

  ownership_input

  constructor(
    public addTrack: AddTrackService,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.addTrack.loadArtistOverview()
    if(this.addTrack.firstWriter){
      this.firstWriter(this.addTrack.firstWriter)
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  openModal(modal){
    document.getElementById(modal).classList.add('active')
  } 

  closeModal(modal){
    document.getElementById(modal).classList.remove('active')
  }

  async saveTrack(){
    if(this.ownership_input && this.ownership_input != 'empty' && this.ownership_input != 'not-hund'){
      console.log(this.track)
    this.track.created_at = new Date(Date.now())
    this.spinner.show()
    await this.addTrack.addTrack(this.track)
    .then(()=>{console.log('trAd'); this.openSnackBar("Track added", "Ok")})
    this.spinner.hide()
    this.track.collection_ids.forEach(id =>{
      this.addTrack.updateWriterTracks(id, 1)
    })
    this.addTrack.updateTracksCount(1)
    this.router.navigate(['/admin/dashboard'])
    this.track= {title: null,
    alt_title: null,
    writers: [],
    collection_ids: [],
    collection_type: "artist", //artist or label ->
    state: "not_published",
    created_at: null,
    published_at: null}
    }
  }

  addWriter(){
    this.track.writers.push(JSON.parse(JSON.stringify(this.addWriterObject)))
    if(this.addWriterObject.rights_to_collect){
      const testString: any = this.addWriterObject.rights_to_collect
      if(testString.length > 5) {
        this.track.collection_ids.push(this.addWriterObject.rights_to_collect)
      }
    }
    this.closeModal('artist-modal')
    this.addWriterObject = {
      rights_to_collect: false,
      first_name: null,
      last_name: null,
      performer_name: null,
      share: null
    }
  }

  firstWriter(writer){
    this.track.writers.push(JSON.parse(JSON.stringify(writer)))
    if(writer.rights_to_collect){
      this.track.collection_ids.push(writer.rights_to_collect)
    }
  }

  selectArtist(writer){
    this.addWriterObject = {
      rights_to_collect: writer.id,
      first_name: writer.first_name,
      last_name: writer.last_name,
      performer_name: writer.performer_name,
      share: null
    }
    this.addWriter()
  }

  inputChange(){
    if(this.searchString.length>3){
      this.searchArtist()
    } else {
      this.addTrack.foundWriters = []
    }
  }

 public completeOwnership(){
    let hund = false
    let empt = false
    let total = 0
    if(this.track.writers.length>0){
      this.track.writers.forEach(writer => {
        if(!writer.share){
          empt = true
        } else {
          total += writer.share
        }
        console.log(writer.share)
      })
      if(total != 100) {
        hund = true
      }
    }
    if(empt){
      this.ownership_input = "empty"
      return "empty"
    } else {
      if(hund){
        this.ownership_input = "not-hund"
        return "not-hund"
      } else {
        this.ownership_input = true
        return true
      }
    }
  }

  searchArtist(){
    this.addTrack.searchForArtist(this.searchString)
    if(this.ownership_input=="empty" || this.ownership_input =="not-hund"){

    } else {

    }
  }

}
