<!-- <div>
    <h1>Import CSV</h1>
    <h2>1. Select what to import</h2>
    <input type="file" (change)="handleFile($event)" name="" id="">
</div> -->
<div class="wrapper">
    <div class="content">
        <h1>Import CSV</h1>
        <p>
            <strong>Important:</strong> 
            Make sure that the files is according to the necessary standard for importing.
            ALSO: Do not change the basic info from step 1 after the import of the CSV. If there is a mistake refresh the window.
            <br>
            In the end don't forget to click save. 
        </p>
        <div>
            <h2>1. Basic info</h2>
            <p>Make sure that imported tracks share these characteristics</p>
            <div>
              <div><strong>Collection Type:</strong></div>
                <select class="artist-sel" name="" id="" [(ngModel)]="tracksType" [disabled]="fileSelected">
                    <option  value="artist">Artist</option>
                    <option  value="label">Label</option>
                </select>
                <div *ngIf="tracksType == 'label' ">
                    <div *ngIf="!selectedLabel">
                        Search for label:
                        <input type="text" name="" id="" (keyup)="inputChange()" [(ngModel)]="searchString">
                        <div *ngFor="let writer of foundLabels" (click)="selectLabel(writer)">
                            {{writer.name}}
                        </div>
                    </div>
                    <div *ngIf="selectedLabel">
                        <strong>Selected Label</strong> {{selectedLabel.first_name + ' ' + selectedLabel.last_name + ' (' + selectedLabel.label_name + ')'}}
                    </div>
                </div>
                <div><strong>Published State:</strong></div>
                <select class="label-sel" name="" id="" [(ngModel)]="tracksState" [disabled]="fileSelected">
                    <option value="published">Published</option>
                    <option value="not_published">Not yet published</option>
                </select>
            </div>
        </div>
        <div class="upload">
            <h2>2. Upload Doc</h2>
            <div class="upload-btn">
                <input type="file" (change)="handleFile($event)" name="" id="">
                Upload CSV
                <mat-icon>upload_file</mat-icon>
            </div>
        </div>
        <div *ngIf="adminService.importedTracks" class="tracks">
            <h2>3. Save tracks</h2>
            <div class="tracksTable">
                <div class="header">
                    <div class="item title"><div class="title">Title</div></div>
                    <div class="item alt"><div class="title">Alt title</div></div>
                    <div class="item artists"><div class="title">artists</div></div>
                    <div class="item date"><div class="title">published</div></div>
                </div>
               <div class="row" *ngFor="let track of adminService.importedTracks" (click)="trackDetails(track)">
                    <div class="title">
                        {{track.title}}
                    </div>
                    <div class="alt">
                        {{track.alt_title}}
                    </div>
                    <div class="artist">
                        {{track.writers[0].first_name + ' ' + track.writers[0].last_name}}
                        <div class="additional" *ngIf="track.writers.length>1">+{{track.writers.length-1}}</div>
                    </div>
                    <div>
                        {{track.published_at ? (track.published_at | date:mediumDate) : (track.created_at | date:mediumDate)}}
                    </div>
                    <div class="registered">
                        <div *ngIf="track.state == 'not_published'">
                            <img style="height: 30px;" src="../../../assets/images/icon_pending.svg" alt="">
                        </div>
                        <div *ngIf="track.state == 'published'">
                            <img style="height: 30px;" src="../../../assets/images/icon_published.svg" alt="">
                        </div>
                    </div>
               </div>
               <div class="save-tracks-cont">
                    <button class="tracks-btn" (click)="saveTracks()">Save Tracks</button> 
               </div>
            </div>
        </div>
    </div>
</div>


<div class="modalWrapper" id="singleTrack">
    <div class="modal">
        <div class="content" *ngIf="viewTrack">
            <div class="header">
                <div>
                    {{viewTrack.title}} 
                </div>
                | 
                <div class="type">
                    {{viewTrack.collection_type}}
                </div>
                <div>
                    <mat-icon (click)="closeModal('singleTrack')">highlight_off</mat-icon>
                </div>
            </div>
            <div class="body">
                <div class="publishedSection">
                    <div class="icon">
                        <img src="../../../assets/images/dashboard/icon_dashboard_tracks.svg" alt="">
                    </div>
                    <div class="published">
                        <div class="stateBtn">
                            <div class="state">
                                <span *ngIf="viewTrack.state == 'not_published'">unpublished</span>
                                <span *ngIf="viewTrack.state == 'published'">published</span>
                                <!-- <img src="../../../assets/images/chevron-down-solid.svg" alt="">  -->
                            </div>
                            <!-- <div class="change" (click)="changeTrackState(viewTrack.id, viewTrack.state)"><span *ngIf="viewTrack.state == 'not_published'">publish</span><span *ngIf="viewTrack.state == 'published'">unpublish</span> </div> -->
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            title
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <div>{{viewTrack.title}}<br>
                            {{viewTrack.alt_title}}
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            artists
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <!-- <div>{{viewTrack.writers[0].performer_name}}
                            <br> {{viewTrack.writers[1]?.performer_name}}
                        </div> -->
                        <div *ngFor="let writer of viewTrack.writers">
                            {{writer.performer_name}}
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            writers
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <div class="line" *ngFor="let writer of viewTrack.writers">
                            {{writer.first_name}} {{writer.last_name}}
                            <div class="ownership">
                                {{writer.share}} %
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn-delete" (click)="deleteTrack(viewTrack.id)"><mat-icon>delete</mat-icon> Delete Track</button>
            </div>
        </div>
    </div>
</div>