import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators'
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private fireAuth: AngularFireAuth,
    private router: Router
  ) { }

  canActivate(): Observable<boolean>{
    return this.fireAuth.authState.pipe(
      map(user => {
          if(user) {
            return true
          }
          else{
            this.router.navigate(['/login'])
            return false;
          } 
      })
    )

  }
}
