import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginData = {
    email: null,
    password: null
  }

  resetEmail = null

  constructor(
    private authService: AuthService,
    private router: Router,
    private fireAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.authState()
  }

  async authState(){
    // this.fireAuth.auth()
    console.log(((await this.fireAuth.currentUser)))
  }

  toClient() {
    this.router.navigate(['/admin/createClient'])
  }

  login(){
    this.authService.login(this.loginData)
  }

  makeMeAdmin(){
    this.authService.makeSuperAdmin()
  }

  async custom(){
    this.authService.checkCustomClaims()
    console.log((await this.fireAuth.currentUser))
  }

  sendReset(){
    this.authService.resetPassword(this.resetEmail)
    this.closeModal('resetModal')
  }

  openModal(modal){
    document.getElementById(modal).classList.add('active')
  } 

  closeModal(modal){
    document.getElementById(modal).classList.remove('active')
  }

}
