import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddTrackComponent } from './add-track/add-track.component';
import { AdminArtistComponent } from './admin/admin-artist/admin-artist.component';
import { AdminArtistsComponent } from './admin/admin-artists/admin-artists.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminMessagesComponent } from './admin/admin-messages/admin-messages.component';
import { AdminThreadsComponent } from './admin/admin-threads/admin-threads.component';
import { AdminTracksComponent } from './admin/admin-tracks/admin-tracks.component';
import { AdminComponent } from './admin/admin.component';
import { CreateClientComponent } from './admin/create-client/create-client.component';
import { FileUploadComponent } from './admin/file-upload/file-upload.component';
import { ProfileSettingsComponent } from './admin/profile-settings/profile-settings.component';
import { ClientDashboardComponent } from './clients/client-dashboard/client-dashboard.component';
import { ClientMessagesComponent } from './clients/client-messages/client-messages.component';
import { ClientTracksComponent } from './clients/client-tracks/client-tracks.component';
import { ClientsComponent } from './clients/clients.component';
import { LoginComponent } from './public/login/login.component';
import { AuthGuardService } from './public/services/guard-service/auth-guard.service';
import { AdminGuardService } from './public/services/admin-guard/admin-guard.service';
import { LabelComponent } from './label/label.component';
import { ClientAddTrackComponent } from './clients/client-add-track/client-add-track.component';
import { ClientProfileComponent } from './clients/client-profile/client-profile.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'admin',
    canActivate: [AuthGuardService, AdminGuardService],
    component: AdminComponent,
    children: [
      { path: 'dashboard', component: AdminDashboardComponent },
      { path: 'all-artists', component: AdminArtistsComponent },
      { path: 'profile', component: ProfileSettingsComponent },
      { path: 'create-client', component: CreateClientComponent },
      { path: 'create-client/:type', component: CreateClientComponent },
      { path: 'client/artist/:id', component: AdminArtistComponent },
      { path: 'client/label/:id', component: AdminArtistComponent },
      { path: 'csv', component: FileUploadComponent },
      { path: 'add-track', component: AddTrackComponent },
      { path: 'all-tracks', component: AdminTracksComponent },
      { path: 'messages', component: AdminThreadsComponent },
      { path: 'messages/:id', component: AdminMessagesComponent }
    ]
  },
  {
    path: 'artist',
    canActivate: [AuthGuardService],
    component: ClientsComponent,
    children: [
      { path: 'dashboard', component: ClientDashboardComponent },
      { path: 'profile', component: ClientProfileComponent },
      { path: 'my-tracks', component: ClientTracksComponent },
      { path: 'add-track', component: ClientAddTrackComponent },
      { path: 'messages', component: ClientMessagesComponent }
    ]
  },
  {
    path: 'label',
    canActivate: [AuthGuardService],
    component: ClientsComponent,
    children: [
      { path: 'dashboard', component: ClientDashboardComponent },
      { path: 'my-tracks', component: ClientTracksComponent },
      // { path: 'add-track' },
      { path: 'messages', component: ClientMessagesComponent }
    ]
  }
  // { path: 'label', canActivate:[AuthGuardService], component: LabelComponent, children: [
  //   // { path: 'dashboard', component: ClientDashboardComponent },
  //   // { path: 'my-tracks', component: ClientTracksComponent },
  //   // // { path: 'add-track' },
  //   // { path: 'messages', component: ClientMessagesComponent }
  // ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
