<div class="wrapper">
    <div class="content">
        <div class="name">
            Andrés Pesqueira
        </div>
        <div class="menu">
            <div class="item" routerLink="/admin/messages">
                <img src="../../../assets/images/navbar/icon_topbar_messages.svg" alt="">
            </div>
            <div class="item" (click)="auth.logOut()">
                <img src="../../../assets/images/navbar/icon_topbar_logout.svg" alt="">
            </div>
        </div>
    </div>
</div>