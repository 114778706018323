<div class="wrapper">
    <div class="content">
      <div class="tracks-header">
        <h2>Add Track</h2>
        <img src="../../../assets/images/dashboard/Skwiglylongline.svg" alt="">
    </div>
        <div>
            <form action="">
                <div>
                    <div class="title">
                      Title
                    </div>
                    <div class="input-cont">
                      <img src="../../assets/images/dashboard/Corner.svg" alt="">
                      <input class="add-input" type="text" [(ngModel)]="track.title" name="title">
                    </div>
                </div>
                <div>
                  <div class="title">Alternative Title</div>
                    <div class="input-cont">
                      <img src="../../assets/images/dashboard/Corner.svg" alt="">
                      <input class="add-input" type="text" [(ngModel)]="track.alt_title" name="alt_title">
                    </div>
                </div>
                
                <div>
                  <div class="title">Writers</div>
                    <div class="writers" *ngFor="let writer of track.writers">
                      {{writer.first_name + ' ' + writer.last_name}} ({{writer.performer_name}}) <br>
                      <div class="input-cont">
                        <img src="../../assets/images/dashboard/Corner.svg" alt="">

                        <input class="perc-input" type="number" (change)="completeOwnership()" name="share" [(ngModel)]="writer.share">
                        <span class="percentage">%</span>
                      </div>
                    </div>
                    <button class="add-writer-btn" (click)="openModal('artist-modal')">
                      Add Writer <img src="../../assets/images/dashboard/icon_button_addtrack_white.svg" alt="">
                    </button>
                    
                    
                    <div class="alert" *ngIf="ownership_input=='not-hund'">
                        Warning! The rights do not add up to 100%
                    </div>
                    <div class="alert" *ngIf="ownership_input=='empty'">
                      Warning! You cannot have writers with no ownership share
                    </div>
                </div>
                <div class="add-track-cont">
                  <button class="add-track-btn" (click)="saveTrack()">Add Track</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal" id="artist-modal">
    <div class="content">
      <div class="header">
        <h3 class="modal-title" id="modal-basic-title">Add a Writer </h3>
        <button type="button" class="close" aria-label="Close" (click)="closeModal('artist-modal')" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="body">
          <div class="writer-title">Add a client writer</div>
          <p>Search for a writer here first to associate the client account.</p>
          <div class="search-cont">
            <input class="searcher" type="text" (keyup)="inputChange()" [(ngModel)]="searchString" placeholder="Search for writer">
            <img class="search-icon" src="../../assets/images/search-solid.svg" alt="">
          </div>
          <div class="selector" *ngFor="let writer of addTrack.foundWriters" (click)="selectArtist(writer);">
            {{writer.name}}
            <img class="plus-icon" src="../../assets/images/plus-solid.svg" alt="">
          </div>
          <div class="writer-title">Add an alternative writer</div>
          <p>Only for the cases where the associated writer does not appear in the above searcher and is thus an external writer to the system.</p>
          <div>
            First Name<br>
            <div class="input-cont">
              <img src="../../assets/images/dashboard/Corner.svg" alt="">
              <input class="add-input" type="text" name="first_name" id="" [(ngModel)]="addWriterObject.first_name">
            </div>
          </div>
          <div>
            Last Name<br>
            <div class="input-cont">
              <img src="../../assets/images/dashboard/Corner.svg" alt="">
              <input class="add-input" type="text" name="last_name" id="" [(ngModel)]="addWriterObject.last_name">
            </div>
          </div>
          <div>
            Performer Name<br>
            <div class="input-cont">
              <img src="../../assets/images/dashboard/Corner.svg" alt="">
              <input class="add-input" type="text" name="performer_name" id="" [(ngModel)]="addWriterObject.performer_name">
            </div>
          
          </div>
          <div>
            <input type="checkbox" [(ngModel)]="addWriterObject.rights_to_collect"> Rights to collect
          </div>
        <!-- <div class="row">
            Clamo a: <strong>{{myClaimService.currentClaimData?.company.name}}</strong> <br>
            Asunto: <strong>{{myClaimService.currentClaimData?.title}}</strong>
        </div>
        <div class="row" style="margin-top: 25px;">
          Si ya has resuelto la reclamación, o crees que no se va a resolver por las buenas y quieres dejar tu opinón sobre {{myClaimService.currentClaimData?.company.name}}, procede a cerrarlo.
        </div>
        <div class="row">
  
        </div> -->
      </div>
      <div class="footer">
        <button type="button" class="btn-orange" (click)="addWriter()">Add Writer</button>
        <button type="button" class="btn-black" (click)="closeModal('artist-modal')">Back</button>
      </div>
    </div>
  </div>