
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-admin-threads',
  templateUrl: './admin-threads.component.html',
  styleUrls: ['./admin-threads.component.scss']
})
export class AdminThreadsComponent implements OnInit {

  constructor(
    public dataService: DataService,
    public router: Router,
    
  ) { }

  ngOnInit(): void {
    this.dataService.getThreads()
  }

  seeThread(thread){
    this.router.navigate(['/admin/messages/', thread.id])
  }

}
