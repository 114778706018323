import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AdminService } from '../services/admin/admin.service';
import { remove } from 'remove-accents';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {
  user = {
    email: null,
    password: null
  };

  userData = {
    first_name: null,
    last_name: null,
    performer_name: null,
    email: null,
    cae_no: null,
    pro: null,
    registered_tracks: 0,
    type: 'artist'
  };

  userLabel = {
    email: null,
    password: null
  };

  labelData = {
    first_name: null,
    last_name: null,
    label_name: null,
    email: null,
    registered_tracks: 0,
    type: 'label'
  };

  constructor(
    private admin: AdminService,
    private fireAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private router: Router,
    private spinner: NgxSpinnerService // private accents: remove
  ) {}

  ngOnInit(): void {
    this.admin.getArtistOverview();

    // this.state()
  }

  // async state(){
  //   console.log("state")

  //   const state = (await this.fireAuth.currentUser);
  //   console.log(state, await this.fireAuth.currentUser);
  //     this.fireAuth.authState.toPromise().then((user)=>{
  //     if (user) {
  //       console.log("gucci")
  //     } else {
  //       console.log("problemo")
  //     }
  //   });
  //   //     (await this.fireAuth.authState).pipe(
  //   //   map(user=>{
  //   //       if(user) {
  //   //       console.log("gucci")
  //   //       return true
  //   //       }
  //   //       else{
  //   //         console.log("problemo")
  //   //         // this.router.navigate(['/login'])
  //   //         return false;
  //   //       }
  //   //   })
  //   // )
  // }

  // async checkClaims(){
  //   await (await this.fireAuth.currentUser).getIdTokenResult().then((res) => {console.log(res)})
  // }

  // testRemove(){
  //   console.log(remove("aáéÉßüöä"), "asdß".toUpperCase())
  //   this.admin.getArtistOverview()
  // }

  // testAdding(){
  //   this.admin.addArtistToOverview("123", "New Artist (XYZ)")
  // }

  createArtistClient() {
    //Validate Data First
    this.spinner.show('create');
    this.userData.email = this.user.email;
    // this.userData = this.user.email;
    this.userData['created_at'] = new Date(Date.now());
    //Call server function to create artist
    this.admin
      .createNewAdminUser(this.user, this.userData)
      .toPromise()
      .then(res => {
        this.spinner.show('hide');
        console.log('created artist', res);
        this.admin.addArtistToOverview(res.artist_id, this.userData.first_name, this.userData.last_name, this.userData.performer_name);
        this.admin.updateArtistsCount(1);
        this.router.navigate(['/admin/client/artist/', res.artist_id]);
      })
      .catch(err => {
        this.spinner.show('hide');
        console.error('not art create', err);
        alert('ERROR - Artist not created');
      });
  }

  createLabelClient() {
    //Validate Data First
    this.spinner.show('create');
    this.labelData.email = this.userLabel.email;
    this.labelData['created_at'] = new Date(Date.now());
    //Call server function to create artist
    this.admin
      .createNewLabelUser(this.userLabel, this.labelData)
      .toPromise()
      .then(res => {
        this.spinner.show('hide');
        console.log('created LABEL', res);
        // this.admin.addArtistToOverview(res.artist_id, this.userData.first_name, this.userData.last_name, this.userData.performer_name)
        this.admin.updateLabelsCount(1);
        this.router.navigate(['/admin/client/label/', res.label_id]);
      })
      .catch(err => {
        this.spinner.show('hide');
        console.error('not art create', err);
        alert('ERROR - Label not created');
      });
  }
}
