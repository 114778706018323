<div class="wrapper">
    <div class="content">
        <div *ngIf="adminService.currentArtist.data">
            <h2><h2 style="color:black">{{adminService.currentArtist.data.first_name}} {{adminService.currentArtist.data.last_name}}</h2> | {{adminService.currentArtist.data.performer_name ? adminService.currentArtist.data.performer_name : adminService.currentArtist.data.label_name}}</h2>
            <div class="options">
                <button class="btn-orange" routerLink="/admin/csv">Add CSV<img src="../../../assets/images/dashboard/icon_button_add_artist_black.svg" alt=""></button>
                <button class="btn-black" routerLink="/admin/add-track" (click)="addFirstWriter()">Add track <img src="../../../assets/images/dashboard/icon_button_addtrack_white.svg" alt=""></button>
            </div>
            <div class="dataWrapper">
                <div class="item" *ngIf="adminService.currentArtist.data.cae_no">
                    <div class="title">CAE</div>
                    <div class="data">{{adminService.currentArtist.data.cae_no}}</div>
                </div>
                <div class="item" *ngIf="adminService.currentArtist.data.pro">
                    <div class="title">pro</div>
                    <div class="data">{{adminService.currentArtist.data.pro}}</div>
                </div>
                <div class="item">
                    <div class="title">email</div>
                    <div class="data">{{adminService.currentArtist.data.email}}</div>
                </div>
                <div class="item">
                    <div class="title">tracks</div>
                    <div class="data">{{adminService.currentArtist.data.registered_tracks}}</div>
                </div>
            </div>
            
            <div class="tracks">
                <h2>tracks</h2>
                <div class="tracksTable">
                    <div class="header">
                        <div class="item title"><div class="title">Title</div></div>
                        <div class="item alt"><div class="title">Alt title</div></div>
                        <div class="item artists"><div class="title">artists</div></div>
                        <div class="item date"><div class="title">published</div></div>
                    </div>
                   <div class="row" *ngFor="let track of adminService.currentArtist.tracks" (click)="trackDetails(track)">
                        <div class="title">
                            {{track.title}}
                        </div>
                        <div class="alt">
                            {{track.alt_title}}
                        </div>
                        <div class="artist">
                            {{track.writers[0].first_name + ' ' + track.writers[0].last_name}}
                            <div class="additional" *ngIf="track.writers.length>1">+{{track.writers.length-1}}</div>
                        </div>
                        <div>
                            {{(track.created_at | date:mediumDate)}}
                        </div>
                        <div class="registered">
                            <div *ngIf="track.state == 'not_published'">
                                <img style="height: 30px;" src="../../../assets/images/icon_pending.svg" alt="">
                            </div>
                            <div *ngIf="track.state == 'published'">
                                <img style="height: 30px;" src="../../../assets/images/icon_published.svg" alt="">
                            </div>
                        </div>
                   </div> 
                </div>
            </div>
            <div *ngIf="adminService.moreArtistTracks">
                <button (click)="adminService.loadMoreArtistTracks(route.snapshot.params.id)">Load more tracks</button>
            </div>
        </div>
    </div>
</div>
<div class="modalWrapper" id="singleTrack">
    <div class="modal">
        <div class="content" *ngIf="viewTrack">
            <div class="header">
                <div>
                    {{viewTrack.title}} 
                </div>
                | 
                <div class="type">
                    {{viewTrack.collection_type}}
                </div>
                <div>
                    <mat-icon (click)="closeModal('singleTrack')">highlight_off</mat-icon>
                </div>
            </div>
            <div class="body">
                <div class="publishedSection">
                    <div class="icon">
                        <img src="../../../assets/images/dashboard/icon_dashboard_tracks.svg" alt="">
                    </div>
                    <div class="published">
                        <div class="stateBtn">
                            <div class="state"><span *ngIf="viewTrack.state == 'not_published'">unpublished</span><span *ngIf="viewTrack.state == 'published'">published</span><img src="../../../assets/images/chevron-down-solid.svg" alt=""> </div>
                            <div class="change" (click)="changeTrackState(viewTrack.id, viewTrack.state)"><span *ngIf="viewTrack.state == 'not_published'">publish</span><span *ngIf="viewTrack.state == 'published'">unpublish</span> </div>
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            title
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <div>{{viewTrack.title}}<br>
                            {{viewTrack.alt_title}}
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            artists
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <!-- <div>{{viewTrack.writers[0].performer_name}}
                            <br> {{viewTrack.writers[1]?.performer_name}}
                        </div> -->
                        <div *ngFor="let writer of viewTrack.writers">
                            {{writer.performer_name}}
                        </div>
                    </div>
                </div>
                <div class="data">
                    <div class="title">
                        <div class="text">
                            writers
                        </div>
                        <img src="../../../assets/images/dashboard/Corner.svg" alt="">
                    </div>
                    <div class="dataContent">
                        <div class="line" *ngFor="let writer of viewTrack.writers">
                            {{writer.first_name}} {{writer.last_name}}
                            <div class="ownership">
                                {{writer.share}} %
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn-delete" (click)="deleteTrack(viewTrack.id, viewTrack.collection_ids)"><mat-icon>delete</mat-icon> Delete Track</button>
            </div>
        </div>
    </div>
</div>


<!-- <input type="file" (change)="handleFile($event)" name="" id=""> -->
<!-- 
<div>
    <div *ngIf="adminService.currentArtist.data">
        {{adminService.currentArtist.data.first_name}} {{adminService.currentArtist.data.last_name}} - {{adminService.currentArtist.data.performer_name}} 
    </div>
    <div>
        
        <button (click)="test()">
            add songs
        </button>
        <div *ngIf="adminService.importedTracks">
            <div *ngFor="let song of adminService.importedTracks">
                {{song.title}} ({{song.alt_title}}) by {{song.writers[0].first_name + ' ' + song.writers[0].last_name}} ({{song.writers[0].performer_name}}) <span *ngIf="song.writers.length>1">and {{song.writers.length - 1}} other(s)</span>
            </div>
        </div>  
    </div>
</div> -->
