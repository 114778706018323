
<div class="wrapper">
    <div class="content">
        <div class="name">
            <span *ngIf="clientData.currentUserData">{{clientData.currentUserData?.first_name + ' ' + clientData.currentUserData?.last_name}}</span>
        </div>
        <div class="menu">
            <div class="item" (click)="routeTo('profile')">
                <img src="../../../assets/images/navbar/icon_topbar_settings.svg" alt="">
            </div>
            <div class="item" (click)="routeTo('messages')">
                <img src="../../../assets/images/navbar/icon_topbar_messages.svg" alt="">
            </div>
            <div class="item" (click)="auth.logOut()">
                <img src="../../../assets/images/navbar/icon_topbar_logout.svg" alt="">
            </div>
        </div>
    </div>
</div>