import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { remove } from 'remove-accents';
import firestore from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  artistsOverview = null;
  allArtists = [];
  labelOverview = null;

  moreArtistTracks;

  currentArtist = {
    id: null,
    data: null,
    tracks: null
  };
  lastArtistTrack;

  importedTracks;

  constructor(private store: AngularFirestore, private functions: AngularFireFunctions) // private firestore: firestore
  {
    // this.functions.useFunctionsEmulator('http://localhost:5001');
  }

  createNewAdminUser(user: { email; password }, userData) {
    const createArtist = this.functions.httpsCallable('createArtist');
    return createArtist({ user, userData });
  }

  createNewLabelUser(user: { email; password }, userData) {
    const createLabel = this.functions.httpsCallable('createLabel');
    return createLabel({ user, userData });
  }

  updateArtistsCount(num: number) {
    this.store
      .collection('overview_docs')
      .doc('overview')
      .update({
        total_artists: firestore.firestore.FieldValue.increment(num)
      });
  }

  updateLabelsCount(num: number) {
    this.store
      .collection('overview_docs')
      .doc('overview')
      .update({
        total_labels: firestore.firestore.FieldValue.increment(num)
      });
  }

  updateTracksCount(num: number) {
    this.store
      .collection('overview_docs')
      .doc('overview')
      .update({
        total_tracks: firestore.firestore.FieldValue.increment(num)
      });
  }

  addArtistToOverview(id, first_name, last_name, performer_name) {
    // var string = 'artists_000'
    this.artistsOverview.artists_0001.push({
      id: id,
      first_name: first_name,
      last_name: last_name,
      performer_name: performer_name,
      name: first_name + ' ' + last_name + ' (' + performer_name + ')'
    });
    const add = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      performer_name: performer_name,
      name: first_name + ' ' + last_name + ' (' + performer_name + ')'
    };
    this.store
      .collection('artists_overview')
      .doc('artists_0001')
      .update({ artists: firestore.firestore.FieldValue.arrayUnion(add) })
      .then(res => console.log(res))
      .catch(err => console.log(err));
    // this.store.collection("artists_overview").doc("artists_0001")
  }

  public async getIndividualArtist(id, force_reload?: boolean) {
    if (!this.currentArtist.data || this.currentArtist.id != id || force_reload) {
      await this.loadIndividualArtist(id);
    }
    return;
  }

  private async loadIndividualArtist(id) {
    await this.store
      .collection('artists')
      .doc(id)
      .ref.get()
      .then(artist => {
        this.currentArtist.id = artist.id;
        this.currentArtist.data = artist.data();
      });
    return;
  }

  async loadArtistTracks(id) {
    this.currentArtist.tracks = [];
    this.store
      .collection('tracks')
      .ref.where('collection_ids', 'array-contains', id)
      .orderBy('created_at', 'desc')
      .limit(11)
      .get()
      .then(tracks => {
        this.lastArtistTrack = tracks.docs[tracks.docs.length - 1];
        if (tracks.docs.length > 10) {
          this.moreArtistTracks = true;
        }
        console.log(tracks.docs);
        tracks.forEach(track => {
          this.currentArtist.tracks.push(track.data());
          this.currentArtist.tracks[this.currentArtist.tracks.length - 1]['id'] = track.id;
          if (this.currentArtist.tracks[this.currentArtist.tracks.length - 1].created_at.seconds) {
            console.log('has seconds');
            this.currentArtist.tracks[this.currentArtist.tracks.length - 1].created_at =
              this.currentArtist.tracks[this.currentArtist.tracks.length - 1].created_at.seconds * 1000 +
              this.currentArtist.tracks[this.currentArtist.tracks.length - 1].created_at.nanoseconds / 1000000;
          }
          if (this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at) {
            if (this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at.seconds) {
              console.log('has seconds');
              this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at =
                this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at.seconds * 1000 +
                this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at.nanoseconds / 1000000;
            }
          }
        });
        if (tracks.docs.length == 11) {
          this.currentArtist.tracks.pop();
        }
        console.log(this.currentArtist);
      });
  }

  loadMoreArtistTracks(id) {
    if (this.moreArtistTracks) {
      this.store
        .collection('tracks')
        .ref.where('collection_ids', 'array-contains', id)
        .orderBy('created_at', 'desc')
        .startAt(this.lastArtistTrack)
        .limit(11)
        .get()
        .then(tracks => {
          this.lastArtistTrack = tracks.docs[tracks.docs.length - 1];
          if (tracks.docs.length < 11) {
            this.moreArtistTracks = false;
          }
          console.log(tracks.docs);
          tracks.forEach(track => {
            this.currentArtist.tracks.push(track.data());
            this.currentArtist.tracks[this.currentArtist.tracks.length - 1]['id'] = track.id;
            if (this.currentArtist.tracks[this.currentArtist.tracks.length - 1].created_at.seconds) {
              console.log('has seconds');
              this.currentArtist.tracks[this.currentArtist.tracks.length - 1].created_at =
                this.currentArtist.tracks[this.currentArtist.tracks.length - 1].created_at.seconds * 1000 +
                this.currentArtist.tracks[this.currentArtist.tracks.length - 1].created_at.nanoseconds / 1000000;
            }
            if (this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at) {
              if (this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at.seconds) {
                console.log('has seconds');
                this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at =
                  this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at.seconds * 1000 +
                  this.currentArtist.tracks[this.currentArtist.tracks.length - 1].published_at.nanoseconds / 1000000;
              }
            }
          });
          if (tracks.docs.length == 11) {
            this.currentArtist.tracks.pop();
          }
          console.log(this.currentArtist);
        });
    }
  }

  public async getArtistOverview(force_reload?: boolean) {
    console.log('get Artists', !this.artistsOverview);
    if (!this.artistsOverview || force_reload) {
      await this.loadArtistOverview();
    }
    return;
  }

  public async getLabelOverview(force_reload?: boolean) {
    console.log('get Artists', !this.labelOverview);
    if (!this.labelOverview || force_reload) {
      await this.store
        .collection('artists_overview')
        .ref.where('type', '==', 'label')
        .get()
        .then(docs => {
          this.labelOverview = {};
          docs.forEach((doc: any) => {
            this.labelOverview[doc.id] = doc.data().labels;
            console.log();
            // this.allArtists.concat(doc.data().artists)
          });
          // console.log(this.artistsOverview)
        });
    }
    return this.labelOverview;
  }

  private async loadArtistOverview() {
    console.log('load Artists');
    // await this.store.collection("artists_overview").ref.where("type","==","artist").get().then((docs)=>{
    //   this.artistsOverview = {}
    //   docs.forEach((doc: any) => {
    //     this.artistsOverview[doc.id] = doc.data().artists
    //     // this.allArtists.concat(doc.data().artists)
    //   });
    //   console.log('artist',this.artistsOverview)
    // })
    await this.store
      .collection('artists_overview')
      .ref.where('type', '==', 'artist')
      .get()
      .then(docs => {
        this.artistsOverview = {};
        docs.forEach((doc: any) => {
          this.artistsOverview[doc.id] = doc.data().artists;
          // this.allArtists.concat(doc.data().artists)
        });
        console.log('artist', this.artistsOverview);
      });
    return;
  }

  searchForArtist(first_name, last_name) {
    const aA = Object.keys(this.artistsOverview);
    var allArtists = [];

    aA.forEach(a => {
      console.log(this.artistsOverview[a], a);
      this.artistsOverview[a].forEach(b => {
        allArtists.push(b);
      });
      // allArtists.concat(this.artistsOverview[a])
    });
    console.log(first_name, last_name, allArtists);
    var artist_id;
    first_name = remove(first_name).toUpperCase();
    last_name = remove(last_name).toUpperCase();

    allArtists.forEach(el => {
      console.log('element: ', el);
      const fn = remove(el.first_name).toUpperCase();
      const ln = remove(el.last_name).toUpperCase();
      console.log(fn, ln, first_name);
      if (fn == first_name && ln == last_name) {
        artist_id = el.id;
      }
    });
    if (!artist_id) artist_id = 'missing';
    console.log(artist_id);
    // const artIndex = allArtists.findIndex( (a) => {this.normCapitazized(a.first_name) == this.normCapitazized(first_name) && this.normCapitazized(a.last_name) == this.normCapitazized(last_name)})
    // if(artIndex < 0) return "missing";
    // else return allArtists[artIndex].id
    return artist_id;
  }

  findIt(el, fn, ln) {
    console.log(el);
    if (this.normCapitazized(el.first_name) == this.normCapitazized(fn) && this.normCapitazized(el.last_name) == this.normCapitazized(ln)) {
      // if (this.normCapitazized(el.first_name) == this.normCapitazized(fn) && this.normCapitazized(el.last_name) == this.normCapitazized(ln)){
      return true;
    } else {
      return false;
    }
  }

  normCapitazized(string) {
    console.log(string);

    return remove(string).toUpperCase();
  }

  async addTrack(track) {
    return this.store.collection('tracks').add(track);
  }

  updateWriterTracks(id, num) {
    return this.store
      .collection('artists')
      .doc(id)
      .update({ registered_tracks: firestore.firestore.FieldValue.increment(num) });
  }

  publishTrack(id) {
    return this.store
      .collection('tracks')
      .doc(id)
      .update({
        state: 'published',
        published_at: new Date(Date.now())
      });
  }

  unpublishTrack(id) {
    return this.store.collection('tracks').doc(id).update({
      state: 'not_published',
      published_at: null
    });
  }

  /**
   * all_artists = [
   *  {
   *    id: ,
   *    name: "FIRST_NAME LAST_NAME (PERFORMER_NAME)",
   *    name_norm:
   *  }
   * ]
   */

  /**
   * song = {
   * title:
   * alt_title:
   * collection_ids: []
   * writers: [
   * {
   * first_name
   * last_name
   * performer_name
   * rights_to_collect: false || id || "missing"
   * }
   * ]
   * }
   */
}
