import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ClientDataService } from '../services/data-service/client-data.service';

@Component({
  selector: 'app-client-messages',
  templateUrl: './client-messages.component.html',
  styleUrls: ['./client-messages.component.scss']
})
export class ClientMessagesComponent implements OnInit, AfterViewInit {

  messageText

  @ViewChildren('supportLoop') messages: QueryList<any>;
  @ViewChild('supportChatArea') scrollFrame: ElementRef;
  supportMsgContainer

  constructor(
    public dataService: ClientDataService
  ) { }

  ngOnInit(): void {
    this.dataService.getUserData()
    this.dataService.getUserMessages()
  }

  ngAfterViewInit(){
    this.supportMsgContainer = this.scrollFrame.nativeElement
    this.supportMsgContainer.scrollTo(0,this.supportMsgContainer.scrollHeight)
    this.messages.changes.subscribe(() => {
      this.supportMsgContainer.scrollTo(0,this.supportMsgContainer.scrollHeight)
    })
  }

  sendMessage(){
    
    this.dataService.sendMessage(this.messageText).then(res => { console.log(res); this.messageText = null;})
  }

}
